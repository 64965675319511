import React , { Component  } from 'react';
import play from '../../assets/play.png';

class VideoBlock extends Component {
    render(){
        if (this.props.language !== 'fr') {
            return false;
        }

        return (
            <div style={{
                paddingBottom: '30px',
                textAlign: 'center',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontSize: '10px',
                margin: 'auto',
                textDecoration: 'none',
                color: 'black'
            }} onClick={this.props.handlevideo}
            >
                <img src={play} alt="Voir la vidéo" style={{width: '48px', height: 'auto'}}/>
                <div>Voir la vidéo</div>
            </div>
        );
    }

}

export default VideoBlock