import React from 'react';
import "./Button.css";
import { lightBlue } from '../../../utils/colors'

const button = (props) => {
    return (
        <button
            style={{ backgroundColor: lightBlue }}
            className={"Button " + props.btnType}
            onClick={props.click}
            disabled={props.disabled} >
            {props.children}
        </button>
    )
}

export default button;