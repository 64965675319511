import React from 'react';

import "./Spinner.css";

const spinner = (props) => {
    return (
        <div className="Spinner" style={props.style}>
            <div className="doubleBounce1"></div>
            <div className="doubleBounce2"></div>
        </div>
    )
}

export default spinner;