import React, { Component } from 'react';
import styled from 'styled-components';
import {contact, footerText} from '../../assets/text/text';
import axios from 'axios';

import Spinner from '../../component/UI/Spinner/Spinner'

import LogoMinWhite from '../../assets/contact/logoMinWhite.png';
import mailWhite from '../../assets/contact/mailWhite.png';
import phoneWhite from '../../assets/contact/phoneWhite.png';
import kissLogo from '../../assets/footer/kissLogo.gif';

import facebook from '../../assets/footer/FacebookPicto.svg'
import linkedinPicto from '../../assets/footer/linkedinPicto.svg'
import TwitterPicto from '../../assets/footer/TwitterPicto.svg'
import YoutubePicto from '../../assets/footer/youtube-brands.svg'
import {GTM} from "../../utils/gtm";


class FooterM extends Component {
    state = {
        inputValue: '',
        sendToFax: '',
        contactModal: {
            isToggled: false,
            success: false,
            error: null,
            waitingAnswer: false,
            response: null
        }
    }

    onChangeHandler = (e) => {
        this.setState({ inputValue: e.target.value })
    }

    onChangeSendToFaxHandler = (e) => {
        this.setState({ sendToFax: e.target.value })
    }

    confirmNewsletterHandler = (event) => { // Useful to prevent reloading on submit, for e.g.
        event.preventDefault();

        this.setState({
            ...this.state,
            contactModal: {
                ...this.state.contactModal,
                isToggled: true,
                waitingAnswer: true
            }
        })

        const formData = {};
        formData['email'] = this.state.inputValue;
        formData['sendToFax'] = this.state.sendToFax;
        formData['lang'] = this.props.language;

        let url = this.props.language === 'fr' ? 'https://beone.kissthebride.fr/newsletter' : 'https://beone.kissthebride.agency/newsletter';

        axios.post(url, formData)
          .then(resp => {
              console.log(resp)
              if (resp.data.success) {
                  this.setState({
                      ...this.state,
                      contactModal: {
                          ...this.state.contactModal,
                          success: true,
                          waitingAnswer: false,
                          response: true
                      }
                  });

                  GTM.add({'event':'newsletter','email': formData.email});
              } else {
                  this.setState({
                      ...this.state,
                      contactModal: {
                          ...this.state.contactModal,
                          error: true,
                          waitingAnswer: false,
                          response: false
                      }
                  })
              }
          })
          .catch(err => {
              this.setState({
                  ...this.state,
                  contactModal: {
                      ...this.state.contactModal,
                      error: true,
                      waitingAnswer: false
                  }
              })
              console.log(err)
          })
    }


    render() {

        let newsletterform = null;
        let newsletterAnswer = null;

        if (!this.state.contactModal.waitingAnswer) {
            newsletterform = (
              <NewsletterButton onClick={this.confirmNewsletterHandler}>
                  {footerText[this.props.language].insideCTA[1]}
              </NewsletterButton>
            )
        } else {
            newsletterform = (<Spinner
              style={{
                  width: '25px',
                  height: '25px',
                  margin: '0 30px'
              }}
            />)
        }

        if (this.state.contactModal.response === true) {
            newsletterAnswer = (
                <NewsletterResponse>
                    {footerText[this.props.language].subscriptionSuccess}
                </NewsletterResponse>
            )
        }
        if (this.state.contactModal.response === false) {
            newsletterAnswer = (
                <NewsletterResponse>
                    {footerText[this.props.language].subscriptionError}
                </NewsletterResponse>
            )
        }

        return (
          <div style={{ overflow: 'hidden' }}>
              <FooterLayout>
                  <LogoBlock>
                      <LogoMin
                        src={LogoMinWhite}
                        height="59"
                        width="174"
                      />
                      <Text>
                          {footerText[this.props.language].title[0]}<br />
                          {footerText[this.props.language].title[1]}
                      </Text>
                      <BoutonKiss href="https://www.kissthebride.fr/" target="_blank">
                          {footerText[this.props.language].boutonKiss}
                      </BoutonKiss>
                  </LogoBlock>
                  <Separateur />
                  <ContactBlock>
                      <a href="tel:+33155390910" >
                          <ContactLogo
                            src={phoneWhite}
                            height="50"
                          />
                      </a >
                      <a href={contact[this.props.language].emailAddress}>
                          <ContactLogo
                            src={mailWhite}
                            height="50"
                          />
                      </a >
                  </ContactBlock>
                  <Separateur />
                  <ContactBlock>

                      <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', justifyContent: 'center' }}>
                          <div style={{
                              fontSize: '12px',
                              fontWeight: '900',
                              textAlign: 'center',

                          }} >
                              {footerText[this.props.language].socialCTA}
                          </div>
                          < div style={{
                              paddingTop: '9px',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-evenly'
                          }}>
                              <SocialLink target="_blank" href="https://www.facebook.com/kissthebrideagency/">
                                  <Social src={facebook} />
                              </SocialLink>
                              <SocialLink target="_blank" href="https://twitter.com/_KissTheBride_">
                                  <Social src={TwitterPicto} />
                              </SocialLink>
                              <SocialLink target="_blank" href="https://www.linkedin.com/company/kissthebride/">
                                  <Social src={linkedinPicto} />
                              </SocialLink>
                              <SocialLink target="_blank" href="https://www.youtube.com/channel/UCLz1x5loy4a8G5yiN8KOQLg">
                                  <Social src={YoutubePicto} />
                              </SocialLink>
                          </div>
                      </div>
                  </ContactBlock>

                  <Separateur />

                  <AdressBlock target="_blank" href="https://www.google.fr/maps/place/58+Rue+du+Temple,+75004+Paris/@48.8605026,2.353009,17z/data=!3m1!4b1!4m5!3m4!1s0x47e66e1ca518dca3:0x257217d148b3773d!8m2!3d48.8604991!4d2.355203">
                      <div style={{ fontSize: '18px', paddingBottom: '4px', fontWeight: 'bold' }}>
                          {footerText[this.props.language].adress[0][0]}<br />
                      </div>
                      {footerText[this.props.language].adress[0][1]}<br />
                      {footerText[this.props.language].adress[0][2]}<br />
                  </AdressBlock>
                  <AdressBlock target="_blank" href="https://www.google.fr/maps/place/100+All%C3%A9e+des+Fr%C3%A8nes,+69760+Limonest/data=!4m2!3m1!1s0x47f49338205bcf69:0xe1656708c60d5564?ved=2ahUKEwiDlOajuPLeAhXMxYUKHaEfC5QQ8gEwAHoECAIQAQ">
                      <div style={{ fontSize: '18px', paddingBottom: '4px', fontWeight: 'bold' }}>
                          {footerText[this.props.language].adress[1][0]}<br />
                      </div>
                      {footerText[this.props.language].adress[1][1]}<br />
                      {footerText[this.props.language].adress[1][2]}<br />
                  </AdressBlock>
                  <AdressBlock target="_blank" href="https://www.google.fr/maps/place/395+Rue+du+G%C3%A9n%C3%A9ral+de+Gaulle,+59700+Marcq-en-Bar%C5%93ul/@50.6484116,3.1144279,17z/data=!3m1!4b1!4m5!3m4!1s0x47c329e8c8316087:0xb9bf8e3d8eb67de0!8m2!3d50.6484082!4d3.1166219">
                      <div style={{ fontSize: '18px', paddingBottom: '4px', fontWeight: 'bold' }}>
                          {footerText[this.props.language].adress[2][0]}<br />
                      </div>
                      {footerText[this.props.language].adress[2][1]}<br />
                      {footerText[this.props.language].adress[2][2]}<br />
                  </AdressBlock>
                  <div style={{ height: '28px' }} />
                  <Separateur />
                  <SocialBlock>
                      <div style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                          {footerText[this.props.language].callToAction[0]}<br />
                          {footerText[this.props.language].callToAction[1]}<br />
                          {footerText[this.props.language].callToAction[2]}<br />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>

                          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                              <NewsLetter>
                                  <NewsLetterInput
                                    placeholder={footerText[this.props.language].insideCTA[0]}
                                    onChange={this.onChangeHandler} />
                                  <NewsLetterInput
                                    className='SendToFax none'
                                    style={{ display: 'none'}}
                                    onChange={this.onChangeSendToFaxHandler}/>
                                  {newsletterform}
                              </NewsLetter>
                          </div>
                          <Separateur style={{ margin: '0 auto' }} />
                          <div style={{ display: 'flex', width: '90%',margin: '0 auto' }}>
                              {newsletterAnswer}
                          </div>
                      </div>
                      <WeakText><a
                        style={{ textDecoration: 'none', color: "rgba(255,255,255, 0.2)" }}
                        href={footerText[this.props.language].rgpdLink}
                        target="_blank"
                        rel="noopener noreferrer">
                          {footerText[this.props.language].rgpd}
                      </a></WeakText>

                  </SocialBlock>

              </FooterLayout>
              <div style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '30px',
                  paddingTop: '30px',
              }}>
                  <BottomLink href="#"> {footerText[this.props.language].linkBottom[0]}</BottomLink>
                  <BottomLink href={footerText[this.props.language].linkBottom[2]} target="_blank">{footerText[this.props.language].linkBottom[1]}</BottomLink>
                  <BottomLink href={footerText[this.props.language].linkBottom[4]} target="_blank">{footerText[this.props.language].linkBottom[3]}</BottomLink>
                  <BottomLinkFake className="JS-cookie-modal-open">{footerText[this.props.language].linkBottom[5]}</BottomLinkFake>
              </div>

              <div style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingBottom: '90px',
                  justifyContent: 'center'
              }}>
                  <KLogo src={kissLogo} height="25" />
                  <LoyaltyMention>Une solution Kiss The Bride</LoyaltyMention>
              </div>

          </div>

        )
    }
}


export default FooterM

const SocialLink = styled.a`
    width : 25px;
`

const Social = styled.img`
    width : 18px;
    padding: 6px;
`

const FooterLayout = styled.div`
    display: flex;
    width: 100vw;
    height: auto;
    z-index: 30;
    align-items: center;
    background: #472a7d ;
    justify-content: space-evenly;
    color: white ;
    padding: 33px 0;
    font-family: "Quicksand";
    flex-direction: column;

`
const LogoBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    align-items: center;
`
const SocialBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 256px;
`
const ContactBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
`
const NewsLetterInput = styled.input`
    border-bottom: 1px solid rgba(255,255,255, 0.2);
    background-color: #472a7d;
    border-color: transparent;
    border-style: unset;
    color: white;
    font-family: 'Quicksand';
    font-size: 14px;
    width: 90%;
    &::-webkit-input-placeholder {
    font-weight: 900;
    color: rgba(255,255,255, 0.2); 
  }
`
const NewsletterButton = styled.button`
    text-align: right;
    font-size: 14px;
    font-weight: 900;
    background-color: #472a7d;
    color: white;
    border: none;
    font-family: "Quicksand";
    width: 120px;
    cursor:pointer;
    padding-right: 0px;
`
const Separateur = styled.div`
    height: 1px;
    width: 90%;
    background: rgba(255,255,255, 0.2);
`
const NewsLetter = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    width: 90%;
`
const AdressBlock = styled.a`
    display: flex;
    flex-direction: column;
    padding-top: 28px;
    font-size: 12px;
    align-items: center;
    text-align: center;
    font-family: "Quicksand";
    font-weight: 900;
    color: white;
    text-decoration: none;
`

const LogoMin = styled.img`
    margin: 10px auto;
`
const ContactLogo = styled.img`
    width : 50px;
    padding: 10px;
`

const KLogo = styled.img`
    width : 20px;
    height: 20px;
    margin-right: 7px;
`

const Text = styled.div`
    padding-top: 0.3em;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
`

const WeakText = styled.div`
    margin-top: 30px;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255, 0.2);
    width: 90%;
    margin: 0 auto;
    text-align: center;
`

const BoutonKiss = styled.a`
border: 2px solid rgba(255,255,255, 0.6);
height: 20px;
width: auto;
border-radius: 27px;
font-family: "Quicksand";
font-size: 14px;
font-weight: bold;
padding: 12px 30px;
margin: 2em 0;
text-decoration: none;
color: white;
`

const BottomLink = styled.a`
font-family: "Quicksand";
font-size: 14px;
font-weight: bold;
margin: 0em 1em;
color: #cccccc;
text-decoration: none;
padding: 5px 0;
`

const BottomLinkFake = styled.div`
font-family: "Quicksand";
font-size: 14px;
font-weight: bold;
margin: 0em 1em;
color: #cccccc;
text-decoration: none;
padding: 5px 0;
`

const LoyaltyMention = styled.div`
font-family: "Quicksand";
font-size: 14px;
font-weight: bold;
color: #cccccc;
text-decoration: none;
padding: 5px 0;
`

const NewsletterResponse = styled.p`
 font-size: 12px;
 font-weight: bold;
`

const LoyaltyCompany = styled.div`
 font-size: 12px;
 font-weight: bold;
`
