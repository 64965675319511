import React from 'react';
import styled, {css} from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor'
import Slider from 'react-slick';

//assets
import {features} from '../../assets/text/text';
import {lightBlue} from '../../utils/colors';

import FeatureIcon1 from '../../assets/features/FeatureIcon1.png';
import FeatureIcon2 from '../../assets/features/FeatureIcon2.png';
import FeatureIcon3 from '../../assets/features/FeatureIcon3.png';
import FeatureIcon4 from '../../assets/features/FeatureIcon4.png';
import FeatureIcon5 from '../../assets/features/FeatureIcon5.png';

import FMleft1 from '../../assets/features/FMleft1.png';
import FMleft2 from '../../assets/features/FMleft2.png';
import FMright3 from '../../assets/features/FMright3.png';
import FMleft4 from '../../assets/features/FMleft4.png';

import FMPlusIconWhite from '../../assets/features/FMPlusIconWhite.png';
import FMMinusIcon from '../../assets/features/FMMinusIcon.png';

import Play from '../../assets/play.png';


import {TextBlock} from '../../component/style/style';

class Features extends React.PureComponent {
  state = {
    isVisible: {
      right: false,
      left: false,
      left2: false,
      center: false
    },
    cardHandler: {
      isVisible: false,
      cardNumber: null
    }
  }



  onVisibilityHandler = (isVisible, side) => {
    if (!this.state.isVisible[side] && isVisible) {
      this.setState({
        ...this.state,
        isVisible: {
          ...this.state.isVisible,
          [side]: true
        }
      })
    }
  }

  onCardClick = (bool, i) => {
    this.props.makeVisible();
    this.setState({
      ...this.state,
      cardHandler: {
        isVisible: bool,
        cardNumber: i
      }
    });
  }



  render() {
    var settings = {
      first: {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
      },
      second: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    };

    const iconArray = [FeatureIcon1, FeatureIcon2, FeatureIcon3, FeatureIcon4, FeatureIcon5]


    return (
      <div>
        <div id="Features"/>
        <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'left')}>
          <LeftPict
            offSetStart={-30}
            left={this.state.isVisible.left}
            src={FMleft1}
            height='600'
            alt="gauchePict"/>
        </VisibilitySensor>
        <TextBlock>

          <Title>
            <b> {features[this.props.language].title[0]}</b>
            {features[this.props.language].title[1]} {features[this.props.language].title[2]}<br/>
            <b>  {features[this.props.language].title[3]}</b>
          </Title>
        </TextBlock>


        <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'right')}>
          <RightPict
            style={{margin: "4rem 0 0 2rem", width: "75%"}}
            offSetStart={30}
            right={this.state.isVisible.right}
            src={FMleft2}
            height='600'
            alt="rightPict"/>
        </VisibilitySensor>

        <TextBlock>
          <Paragraph>
            {features[this.props.language].text}
          </Paragraph>
        </TextBlock>


        <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'center')}>
          <LeftPict
            offSetStart={-30}
            left={this.state.isVisible.center}
            src={FMleft4}
            height='600'
            alt="gauchePict"/>
        </VisibilitySensor>

        <Slider className='iconSlider2' {...settings.first}>
          {features[this.props.language].iconArray.map((icon, i) => {
            return (
              <div key={i}>
                <Pictodiv>
                  <img src={iconArray[i]} width="80" alt={'iconPLus'} style={{}}/>
                  <div style={{paddingTop: '1em', fontSize: '1em', textTransform: 'uppercase'}}><b>{icon[0]}</b></div>
                  <div style={{paddingTop: '0.3em', fontSize: '1em',}}><b>{icon[1]}</b></div>
                </Pictodiv>
              </div>
            )
          })}
        </Slider>

        <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'left2')}>
          <LeftPict
            style={{width: "100%"}}
            offSetStart={30}
            left={this.state.isVisible.left2}
            src={FMright3}
            height='600'
            alt="gauchePict"/>
        </VisibilitySensor>
        <TextBlock>
          <Subtitle>{features[this.props.language].subtitle}</Subtitle>
          <Paragraph>
            {features.fr.text2}
          </Paragraph>
        </TextBlock>
        <Slider className='cardSlider' {...settings.second}>
          {features.fr.popInArray.map((card, i) => {

            return (
              <div key={i}>
                <CardDiv>
                  <h3 style={{ width: '70%', fontSize: '1.2em', paddingBottom: '1em' }}><b>{card[0]}</b></h3>
                  <img
                    className="cardCarroussel"
                    src={FMPlusIconWhite}
                    width="60"
                    alt={'iconPLus'}
                    style={{paddingTop: '1em'}}
                    onClick={() => this.onCardClick(true, i)}
                  />
                </CardDiv>
              </div>

            )
          })}
        </Slider>

        {this.state.cardHandler.isVisible && this.props.status ? (
          <InnerCard>
            <div style={{
              width: '70%',
              fontSize: '1.2em',
              paddingBottom: '0.5em',
              marginTop: '1em'
            }}>
              <b>{features.fr.popInArray[this.state.cardHandler.cardNumber][0]}</b>
            </div>
            <ul style={{ margin: 0, padding: 0 }}>
              {features.fr.popInArray[this.state.cardHandler.cardNumber][1].map((li, i) => {
                return <InnerCardLi key={i}>{li}</InnerCardLi>
              })}
            </ul>
            <InnerCardButton onClick={() => this.onCardClick(false, null)}>
              <img
                className="cardCarroussel"
                src={FMMinusIcon}
                width="35"
                height="35"
                alt={'iconPLus'}
              />
            </InnerCardButton>

          </InnerCard>
        ) : null}
        <LogoIntroFonctionnalites className="LogoIntroFonctionnalites">
          <a onClick={lightbox_open}
             className="Intro-videoFonctionnalites">
            <img src={Play} alt="Regarder la vidéo"></img>
              <div>Regarder la vidéo</div>
          </a>
        </LogoIntroFonctionnalites>
        <div style={{height: '10vh'}}/>
      </div>

    )
  }
}

function lightbox_open() {
  document.getElementById('light').style.display = 'flex';
  document.getElementById('fade').style.display = 'block';
  document.getElementsByTagName("html")[0].style.overflow = "hidden";
}



export default Features

const LogoIntroFonctionnalites = styled.div`
display: flex;
justify-content: center;
margin-top: 20px;
`
  


const Img = styled.img`
        width: 93%;
    height: auto;
    margin: 4rem 0 0 -3rem;
    opacity: 0;
    transform: translateX(${props => props.offSetStart}vh);
`


const RightPict = styled(Img)`
${props => props.right && css`
animation-duration: 1s;
animation-name: fadeInRight;
animation-fill-mode: forwards;
    @keyframes fadeInRight {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(10vh)
    }
  }
`}    
`

const LeftPict = styled(Img)`
    margin: 2rem 0 0 4rem;
    ${props => props.left && css`
    animation-duration: 1s;
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
        @keyframes fadeInLeft {
        from {
          opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateX(-10vh)
        }
      }
    `}    
`
const Pictodiv = styled.div`
display: flex !important;
text-align: center;
flex-direction: column;
align-items: center;
flex-wrap: wrap;
flex-direction: column; 
align-self: center;
height: 265px;
margin: 0 15px;
padding-top: 2rem;
width: auto !important;
font-family: "Quicksand";
`


const CardDiv = styled.div`
display: flex !important;
flex-direction: column;  
align-items: center;
justify-content: center;
text-align: center;
/* width: 80% !important; */
align-self: center;
background: linear-gradient(to bottom right,#472a7d,#5fc6f0);
color: white;
font-weight: bold;
height: 250px;
margin: 0 15px;
box-shadow: 0px 5px 17px 0px #808080b3;
`

const InnerCard = styled.div`
display: flex !important;
flex-direction: column;  
position: fixed;
top: 56px;
padding-bottom: 60px;
z-index: 30;

align-items: center;
justify-content: center;
text-align: center;
align-self: center;
background: linear-gradient(to bottom right,#472a7d,#5fc6f0);
color: white;
font-weight: bold;
height: calc(100vh - 56px - 60px);
width: 100vw;
font-family: "Quicksand";
`

const InnerCardButton = styled.div`
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
background: currentColor;
position: absolute;
bottom: 0;
width: 100%;
height: 60px;
`

const Title = styled.div`
width: 90%;
font-family: "Quicksand";
font-size: 1.5rem;
`
const Subtitle = styled.div`
font-size: 1em;
color: ${lightBlue};
font-weight: 700;
width: 90%;
`
const InnerCardLi = styled.li`
font-size: 0.8em;
color: white;
font-weight: 500;
padding: 0.5em 1em;
list-style-position: inside;
`


const Paragraph = styled.div`
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`

