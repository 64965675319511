import React from 'react';
import styled, {css} from 'styled-components';
import Switch from '../UI/switchButton/Switch';
import beOneMin from '../../assets/Be-OneMin.png';
import {blue} from '../../utils/colors';
import phoneIcon from '../../assets/phoneIcon.png';
import connexionIcon from '../../assets/connexionIcon.png';
import burgerMenu from '../../assets/burgerMenu.png';
import CloseBurgerMenu from '../../assets/CloseBurgerMenu.png';
import {navbarText} from '../../assets/text/text';

import TextGalaxy from '../TextGalaxy/TextGalaxy';
import Features from '../../containers/vuesMobile/FeaturesM';

/* import { TweenMax } from 'gsap';
import "gsap/ScrollToPlugin";
 */

// Insipiration
//https://www.muse-motivation.fr/inspirations-2/

class NavBar extends React.PureComponent {
  state = {
    scrollTop: 0,
    toggleNavbar: false,
    scrollThresHold: false,
    isBurgerMenuToggle: false,
    isContactFormToggle: false,
    isScrollNearTheEnd: false,
    currentView: 'whyBeOne',
    prevView: 'whyBeOne',
    sectionOffSettop: {
      whyBeOne: null,
      featureOne: null,
      who: null,
      contact: null,
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  onBurgerMenuHandler = () => {
    this.setState(prevState => {
      return {
        isBurgerMenuToggle: !prevState.isBurgerMenuToggle
      }
    })
  };

  handleScroll = (e) => {

    // handling different event object ("document" or "div")
    let scrollEl = e.srcElement.scrollingElement ? e.srcElement.scrollingElement : e.srcElement;

    const scrollHeightRemaming = scrollEl.scrollHeight - scrollEl.scrollTop;
    if (scrollEl) {
      // fire an boolean to app when user scroll to contact form at the end of the page
      if (scrollHeightRemaming < 1700 && !this.state.isScrollNearTheEnd) {
        this.setState({
          isScrollNearTheEnd: true
        });
        this.props.handleScrollNearEnd(true);
      } else if (scrollHeightRemaming > 1700 && this.state.isScrollNearTheEnd) {
        this.setState({
          isScrollNearTheEnd: false
        });
        this.props.handleScrollNearEnd(false);
      }

      // keep track of scrollTop in state an letting know <app/> to update <TraitUnion/> accordingly & navbar display
      this.setState({scrollTop: scrollEl.scrollTop})
      if (this.state.scrollTop > 400 && !this.state.scrollThresHold) {
        this.setState({
          scrollThresHold: true,
          toggleNavbar: true
        });
        this.props.handleTrait(true);

      } else if (this.state.scrollTop < 400 && this.state.scrollThresHold) {
        this.setState({
          scrollThresHold: false,
          toggleNavbar: false
        });
        this.props.handleTrait(false)
      }
    }
  };

  onLinkHandler = () => {
    this.setState({
      isBurgerMenuToggle: false
    })
  };

  onMouseEnter = (i) => {
    this.setState(prevState => {
      return {
        currentView: i,
        prevView: prevState.currentView
      }
    })
  };

  onMouseLeave = () => {
    this.setState({
      currentView: this.state.prevView
    });
  };


  onToggleContactForm = (bool) => {
    this.props.toggleContactForm(!bool);
    this.setState(prevState => {
      return {
        isContactFormToggle: !prevState.isContactFormToggle
      }
    })
  };

  render() {

    const {toggleNavbar, isBurgerMenuToggle} = this.state;

    let menu = null;
    if (isBurgerMenuToggle) {
      menu = (<Menu>
        <MenuLink onClick={this.onLinkHandler} href="#WhyBeOne">{navbarText[this.props.language].why}</MenuLink>
        <MenuLink onClick={this.onLinkHandler} href="#Features">{navbarText[this.props.language].features}</MenuLink>
        <MenuLink onClick={this.onLinkHandler} href="#WhoWeAre">{navbarText[this.props.language].who}</MenuLink>
        <Switch isChecked={this.props.language === 'fr' ? true : false}
                onLanguageChange={() => this.props.onLanguageChange()}/>
      </Menu>)
    }

    return (<NavLayoutMobile active={toggleNavbar} onClick={this.props.onClick}>
      {menu}
      <SectionLink onClick={this.onBurgerMenuHandler} account>
        <InnerLinkMobile>
          <img src={this.state.isBurgerMenuToggle ? CloseBurgerMenu : burgerMenu} height="15" alt='burger'/>
        </InnerLinkMobile>
      </SectionLink>
      <LogoMin src={beOneMin} height="30"/>
      <LinkBlock>
        <SectionLink account href="https://www.be-one.fr/ "
                     target="_blank">
          <InnerLinkMobile>
            <img src={connexionIcon} height="20" alt='phoneIcon'/>
          </InnerLinkMobile>
        </SectionLink>
      </LinkBlock>
    </NavLayoutMobile>);
  }
}

export default NavBar

const NavLayoutMobile = styled.div`
    overflow-x: hidden;
    width: 100vw;
    height: 3.5rem;
    position: fixed;
    display: flex;
    justify-content: space-between;
    background-color: white;
    z-index: 2000;
    opacity: 0;
    ${props => props.active && css`
    animation-duration: .4s;
    animation-name: easeInOut;
    animation-fill-mode: forwards;
      @keyframes easeInOut {
        from {
        }
        to {
            opacity: 1;
        }
      }
    `}
`
const LinkBlock = styled.div`
    display: flex;
`
const Menu = styled.div`
    display: flex;
    width: 100vw;
    height: 93vh;
    position: fixed;
    top: 3.5rem;
    background-color: white;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const SectionLink = styled.a`
    width: 10rem;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    display: flex;
    font-family: "Quicksand";
    font-weight: bold;
    color: grey;
    font-size: 14px;
    text-decoration:none;
    cursor:pointer;
    flex-direction: column;
    ${props => props.contact && css`
    background: rgb(95, 198, 240, .8);
    color: white;
    `}
    ${props => props.box && css`
    width: 4rem;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    `}
    ${props => props.account && css`
    width: 80px;
    `}
`
const InnerLinkMobile = styled.div`
    padding-bottom: 0.5rem;
`

const MenuLink = styled.a`
    align-content: center;
    color: ${blue};
    font-family: "Quicksand";
    font-size: 1.5rem;
    text-decoration: none;
    cursor:pointer;
`

const LogoMin = styled.img`
   margin: auto 0;
`