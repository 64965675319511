import React from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor'

//assets
import v2left from '../../assets/v2M/v2left.png';
import v2right from '../../assets/v2M/v2right.png';
import plusIcon from '../../assets/plusIcon.png';
import { secondText } from '../../assets/text/text';
import { lightBlue } from '../../utils/colors';

class SecondM extends React.PureComponent {
    state = {
        isVisible: {
            right: false,
            left: false
        }
    }

    onVisibilityHandler = (isVisible, side) => {
        if (!this.state.isVisible[side] && isVisible) {
            this.setState({
                ...this.state,
                isVisible: {
                    ...this.state.isVisible,
                    [side]: true
                }
            })
        }
    }

    render() {

        return (
            <div>
                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'left')}>
                    <LeftPict
                        offSetStart={-30}
                        left={this.state.isVisible.left}
                        src={v2left}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>
                <TextBlock>
                    <Title>
                        {secondText[this.props.language].title[0]} <br />
                        <b>{secondText[this.props.language].title[1]}</b><br />
                        {secondText[this.props.language].title[2]}
                        <b>{secondText[this.props.language].title[3]}</b>
                    </Title>
                </TextBlock>
                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'right')}>
                    <RightPict
                        offSetStart={30}
                        right={this.state.isVisible.right}
                        src={v2right}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>
                <TextBlock>
                    <Subtitle>{secondText[this.props.language].subtitle}</Subtitle>
                    <Paragraph>
                        <SecondTitle>{secondText[this.props.language].text0}</SecondTitle>
                        {secondText[this.props.language].text1}
                    </Paragraph>
                </TextBlock>
                <IconBlock>
                    {secondText[this.props.language].iconArray.map((sub, i) => {
                        return (
                            <div key={i} style={{ width: '50%', paddingTop: '2rem' }}>
                                <img src={plusIcon} width="60" alt={'iconPLus'} />
                                <div style={{ fontSize: '0.8em', paddingTop: '1em' }}><b>{sub}</b></div>
                            </div>)
                    })}
                </IconBlock>
            </div >
        )
    }
}


export default SecondM


const Img = styled.img`
    width: 70%;
    height: auto;
    margin: 2rem 0 0 3rem;
    opacity: 0;
    transform: translateX(${props => props.offSetStart}vh);
`

const LeftPict = styled(Img)`
    width: 93%;
    height: auto;
    margin: 3rem 0 0 4rem;
    ${props => props.left && css`
    animation-duration: .5s;
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
        @keyframes fadeInLeft {
        from {
          opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateX(-10vh)
        }
      }
    `}    
`

const RightPict = styled(Img)`
${props => props.right && css`
animation-duration: .5s;
animation-name: fadeInRight;
animation-fill-mode: forwards;
    @keyframes fadeInRight {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(10vh)
    }
  }
`}    
`


const TextBlock = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-family: "Quicksand";
flex-direction: column;
text-align: center;
padding-top: 2rem;
width: 100%;
`
const IconBlock = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
padding-top: 2em;
align-items: center;
text-align: center;
width: 100%;
align-self: center;
`
const Title = styled.div`

width: 90%;
font-family: "Quicksand";
font-size: 1.8rem;
`
const Subtitle = styled.div`
font-size: 1em;
color: ${ lightBlue};
font-weight: 700;
width: 90%;
`
const Paragraph = styled.div`
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`

const SecondTitle = styled.h2`
display: inline;
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`
