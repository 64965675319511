import React , { Fragment} from 'react';
import styled from 'styled-components';

//containers Mobile
import IntroViewM from './containers/vuesMobile/IntroM';
import FirstViewM from './containers/vuesMobile/FirstM';
import SecondViewM from './containers/vuesMobile/SecondM';
import ThirdViewM from './containers/vuesMobile/ThirdM';
import FourthViewM from './containers/vuesMobile/FourthM';
import FifthViewM from './containers/vuesMobile/FifthM';
import SixthViewM from './containers/vuesMobile/sixthM';
import FeaturesM from './containers/vuesMobile/FeaturesM';
import WhoViewM from './containers/vuesMobile/WhoM';
import FooterM from './containers/vuesMobile/FooterM';
import VideoView from './containers/vuesMobile/VideoView';
import CookieBanner from './component/CookieBanner/CookieBanner';
import CookieModal from './component/CookieModal/CookieModal';

//components
import NavBar from './component/Navbar/Navbar';
import ContactFormMobile from './component/contactForm/ContactFromMobile';
import DefaultOverlay from "./component/DefaultOverlay/DefaultOverlay";

class App extends React.PureComponent {
  state = {
    hasLeaveIntro: false,
    language: 'fr',
    display: 'block',
    displayMode: null,
    hasReachedContact: false,
    status: true,
    video: false
  };

  hostname = null;

  breakpoint = 1200;

  componentDidMount() {
    if (!this.state.displayMode) {
      if (window.innerWidth > this.breakpoint) {
        this.setState({
          displayMode: 'desktop'
        })
      } else {
        this.setState({
          displayMode: 'mobile'
        })
      }
    }

    this.hostname = window.location.hostname;
    if (this.hostname.search('localhost|agency|beone2') >= 0) {
      this.setState({language: 'en'})
    }
    else {
      this.setState({language: 'fr'})
    }

    window.addEventListener('resize', this.handleResize, true);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleClick = () => {
    if (this.state.status) {
      this.setState({
          status: false
      });
    }
  };

  handleClickVideo = (event) => {
      this.setState({
          video: !this.state.video
      })
  }

  handleClose = (event) => {
      this.setState({
          video: false
      })
  }

  makeVisible = () => {
    this.setState({
        status: true
    })
  };


  handleScroll = (e) => {
    if (e.target.innerWidth > this.breakpoint) {
      //const trait = document.getElementById('TraitUnion');
    }
  };

  handleResize = (e) => {
    if (e.target.innerWidth > this.breakpoint) {
      this.setState({
        displayMode: 'desktop'
      })
    } else {
      this.setState({
        displayMode: 'mobile'
      });
    }
  };

  onScrollThreshold = (bool) => {
    this.setState({hasLeaveIntro: bool});
  };

  onScrollNearTheEnd = (bool) => {
    this.setState({hasReachedContact: bool});
  };

  onLanguageChangeHandler = () => {
    let language = this.state.language === 'fr' ? 'en' : 'fr';
    this.setState({
      language: language
    });

    if(language === 'en'){
      //window.location = 'http://localhost:8000';
      //window.location = 'https://beone2.numendo.com';
      window.location = 'https://beone.kissthebride.agency';
    }
    else {
      //window.location = 'http://127.0.0.1:8000';
      //window.location = 'https://beone.numendo.com';
      window.location = 'https://beone.kissthebride.fr';
    }
  };

  ToggleContactFormHandler = (bool) => {
    let mode = null;
    bool ? mode = 'none' : mode = 'block';
    this.setState({
      display: mode,
    })
  };

  onScrollButtonClick = () => {
    const firstY = document.querySelector('.whyBeOne').offsetTop;
    window.scrollTo(0, firstY)
  };

  render() {
    //Mobile Layout
      if (!this.state.video) {
          return (
              <div style={{overflowX: 'hidden'}}>
                <div style={{display: this.state.display}}>
                  <NavBar
                      handleTrait={this.onScrollThreshold}
                      onLanguageChange={this.onLanguageChangeHandler}
                      onClick={this.handleClick}
                      language={this.state.language}
                      handleScrollNearEnd={this.onScrollNearTheEnd}/>
                  <IntroViewM language={this.state.language} handlevideo={this.handleClickVideo} />
                  <FirstViewM language={this.state.language}/>
                  <SecondViewM language={this.state.language}/>
                  <ThirdViewM language={this.state.language}/>
                  <FourthViewM language={this.state.language}/>
                  <FifthViewM language={this.state.language}/>
                  <SixthViewM language={this.state.language}/>
                  <FeaturesM language={this.state.language} status={this.state.status} makeVisible={this.makeVisible}/>
                  <WhoViewM language={this.state.language}/>
                  <FooterM language={this.state.language}/>

                </div>
                <ContactFormMobile
                    language={this.state.language}
                    toggleContactForm={this.ToggleContactFormHandler}
                    displayMode={this.state.displayMode}
                    showContactForm={false}
                    BottomReached={this.state.hasReachedContact}
                />
                <CookieBanner></CookieBanner>
                <CookieModal></CookieModal>
                <DefaultOverlay></DefaultOverlay>
              </div>
          );
      } else {
          return (
              <IntroPopup>
                <Popup>
                  <PopupClose onClick={this.handleClickVideo}>x</PopupClose>
                    <div style={{
                        position: 'relative',
                        margin: 0,
                        padding: 0,
                        border: 0,
                        fontSize: '100%',
                        verticalAlign: 'baseline',
                        background: 'transparent',
                    }}>
                        <img src="data:image/gif;base64,R0lGODlhEAAJAIAAAP///wAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDUgNzkuMTYzNDk5LCAyMDE4LzA4LzEzLTE2OjQwOjIyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0MEUwMUI2RjBCNTcxMUU5ODVENjhEOUFFNDFGOTczNSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0MEUwMUI3MDBCNTcxMUU5ODVENjhEOUFFNDFGOTczNSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQwRTAxQjZEMEI1NzExRTk4NUQ2OEQ5QUU0MUY5NzM1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjQwRTAxQjZFMEI1NzExRTk4NUQ2OEQ5QUU0MUY5NzM1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAQAAAAAsAAAAABAACQAAAgqEj6nL7Q+jnJQVADs="
                             alt=""
                             style={{
                                 position: 'relative',
                                 width: '80vw',
                                 height: 'auto',
                                 maxHeight: '80vh',
                             }}
                        />
                        <VideoView handleclose={this.handleClose}/>
                    </div>
                </Popup>
              </IntroPopup>

          );
      }

  }
}

export default App;

const IntroPopup = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
`

const Popup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PopupClose = styled.div`
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    background-color: white;
`