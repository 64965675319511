import React from 'react';
import styled from 'styled-components';
import * as $ from 'jquery';
import Slider from "react-slick";
import VideoBlock from "./VideoBlock";

//assets
import logo from '../../assets/mobileLogo.png';
import localNational from '../../assets/localNational.png';
import onOffline from '../../assets/onOffline.png';
import physiqueDigital from '../../assets/physiqueDigital.png';
import loyaltyBeOneVideoFr from '../../assets/Videos/Loyalty_BeOne_FR_05_7Mo.mp4';
import loyaltyBeOneVideoEn from '../../assets/Videos/Loyalty_BeOne_UK_03_7Mo.mp4';
import {introText} from '../../assets/text/text';


const IntroView = (props) => {

  function handleClick(evt) {
    evt.stopPropagation();
    console.log('Button Clicked')
  }

  const iconArray = [
    [onOffline, 'LE ON ET OFFLINE'],
    [physiqueDigital, 'LE PHYSIQUE ET LE DIGITAL'],
    [localNational, 'LE LOCAL ET LE NATIONAL']
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  };

  return (
    <Layout>
      <Fade id="fade" onClick={lightbox_close}>
        <Light id="light">
          <Boxclose className="boxclose" id="boxclose" onClick={lightbox_close}>x</Boxclose>

          {/* eslint-disable-next-line no-restricted-globals */}
          <VisaChipCardVideo id="VisaChipCardVideo" onClick={handleClick} controls autoPlay>
            <source src= {props.language === 'fr' ? loyaltyBeOneVideoFr : loyaltyBeOneVideoEn }></source>
          </VisaChipCardVideo>
        </Light>
      </Fade>
      <Img src={logo} alt="Be-One" style={{width: '70%', height: 'auto', marginTop: '4rem', maxWidth: '350px'}}/>
      <Content>
        <TextBlock>
          <Usp><MainTitle>{introText[props.language].uspM[0]} {introText[props.language].uspM[1]}</MainTitle>{introText[props.language].uspM[2]}</Usp>
        </TextBlock>

        <VideoBlock language={props.language} handlevideo={props.handlevideo}/>

        <Slider className='iconSlider' {...settings} style={{marginBottom: "2em"}}>
          {iconArray.map((icon, i) => {
            return (
              <IconBlock key={i}>
                <img src={icon[0]} width="100" alt={icon[1]}/>
                <div style={{fontSize: '1em', paddingTop: '2em'}}><b>{icon[1]}</b></div>
              </IconBlock>
            )
          })}
        </Slider></Content>
    </Layout>
  )
};

function lightbox_open() {
  var lightBoxVideo = document.getElementById("VisaChipCardVideo");
  document.getElementById('light').style.display = 'flex';
  document.getElementById('fade').style.display = 'block';
  document.getElementsByTagName("html")[0].style.overflow = "hidden";
  lightBoxVideo.play();
}

function lightbox_close() {
  var lightBoxVideo = document.getElementById("VisaChipCardVideo");
  document.getElementById('light').style.display = 'none';
  document.getElementById('fade').style.display = 'none';
  document.getElementsByTagName("html")[0].style.overflow = "scroll";
  lightBoxVideo.pause();
}

$(window).on('load', function() {
  lightbox_open();
});

$('.Intro-videoFonctionnalites').on('click', function() {
  lightbox_open();
  console.log('fdsgfdgq');
});


export default IntroView

const Layout = styled.div`
    width : 100vw;
    height : auto;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const Img = styled.img`

`;

const Fade = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 2001;
`;

const Light = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 2002;
`;

const VisaChipCardVideo= styled.video`
  z-index: 3002;
  width: 100%;
  @media screen and (orientation:landscape) {
    width: calc(100% - 140px);
  }
`;

const Boxclose = styled.a `
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  background-color: white;
  z-index: 4002;
  color: #5eb5e0;
`;

const Content = styled.div`
    display: flex;
    animation-duration: 2s;
    flex-direction: column;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    
    width: 100%;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
          opacity: 1;
      }
    }
    `;

const TextBlock = styled.div`
    display: flex;
    justify-content: center;
    font-family: "Quicksand";
    flex-direction: column;
    text-align: center;
    width: 80%;
    align-self: center;
    padding-bottom: 2rem;
    
`;
const IconBlock = styled.div`
    display: flex !important;
    text-align: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column; 
    align-self: center;
    font-weight: bold;
    height: 150px;
    margin: 0 15px;
    width: auto !important;
`;



const Usp = styled.div`
    font-size: 1em;
    line-height: 22px;
`;

const MainTitle = styled.h1`
    font-size: 1em;
    line-height: 22px;
    padding-top: 2.5em;
`;

