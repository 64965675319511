import React from 'react';
import styled from 'styled-components';
import './Switch.css'

class Switch extends React.Component {

    state = {
        isChecked: null
    };
    componentWillMount() {
        this.setState({ isChecked: this.props.isChecked });
    }
    handleChange = () => {
        this.setState({ isChecked: !this.state.isChecked });
        this.props.onLanguageChange();
    };


    render() {

        const { isChecked } = this.state;

        return (
            <div className="switch-container">
                <label>
                    <input ref="switch"
                        checked={this.state.isChecked}
                        onChange={this.handleChange}
                        className="switch"
                        type="checkbox" />
                    <div>
                        <LangLabel marginL='15' color={isChecked ? 'white' : '#47267f'}>EN</LangLabel>
                        <LangLabel marginL='55' color={isChecked ? '#47267f' : 'white'}>FR</LangLabel>
                        <div></div>
                    </div>
                </label>
            </div>
        );
    }
}

export default Switch



const LangLabel = styled.span`
    margin-left: ${props => props.marginL}px;
    color: ${props => props.color};
    z-index: 99;
`;