

// Info :
// Chaque vue de la page à un objet ( variable javascript qui commence par "const") qui contient les textes à afficher
// Chaque Objet a une clé "fr" et une clé "en"
// Les différents "***" suivi d'une virgule représente des bouts de la phrase, suivi soit une césure, soit d'un changement de police en gras (bold) il faut bien les respecter, et ne pas en ajouter de nouveau
// à l'intérieur d'un tableau ["****", "****", '****'] le nombre d’éléments doit rester le même pour que le script puisse l'interpreter


export const navbarText = {
    fr: {
        why: 'Pourquoi Be-One',
        features: 'Fonctionnalités',
        who: 'Qui sommes-nous',
        contact: 'Contactez-nous'
    },
    en: {
        why: 'Why Be-One',
        features: 'Features',
        who: 'Who we are',
        contact: 'Contact us'
    },
}

export const introText = {
  fr: {
    tagline: [
      "Le ", "trait d'union ", "entre vous et vos magasins."
    ],
    uspD: [
      "Be-One est une plateforme marketing omnicanale innovante ",
      "vous offrant une vision globale, unifiée et ultra-ciblée de l’activité de votre réseau et de votre clientèle pour réconcilier :"
    ],
    uspM: [
      "Be-One",
      "plateforme marketing omnicanale innovante",
      "BeOne vous offre une vision globale, unifiée et ultra-ciblée de l’activité de votre réseau et de votre clientèle afin de développer la digitalisation de vos magasins en réconciliant :"
    ],
    icon: [
      'LE ON ET OFFLINE',
      'LE PHYSIQUE ET LE DIGITAL',
      'LE LOCAL ET LE NATIONAL'
    ]
  },
  en: {
    tagline: [
      "Your ", "link ", "to your stores"
    ],
    uspD: [
      "Be-One is an innovative omni-channel marketing platform ",
      "providing you with a smooth, highly targeted overview of your network activity and customer base that helps you reconcile :"
    ],
    uspM: [
      "Be-One is an ",
      "innovative omni-channel marketing platform ",
      "providing you with a smooth, highly targeted overview of your network activity and customer base that helps you reconcile :"
    ],
    icon: [
      'ON AND OFFLINE',
      'PHYSICAL AND DIGITAL',
      'LOCAL AND NATIONAL'
    ]
  }
};

export const firstText = {
  fr: {
    title: [
      "Créer un lien unique ", "entre vous...", "et vos clients."
    ],
    subtitle: "Be-One centralise toutes vos données on et offline.",
    text: "Notre plateforme campagne marketing vous permet ainsi d’affiner votre connaissance client et d’offrir une expérience fluide, personnalisée et sans couture sur tous vos points de contact pour :",
    iconArray: ["D'ENGAGEMENT", "DE FIDÉLISATION", "DE PROFITABILITÉ", "DE CONFIANCE"]
  },
  en: {
    title: [
      "Creating a single link ", "between you...", "and your customers"
    ],
    subtitle: "Be-One centralises all of your online and offline data",
    text: "It also deepens your knowledge of your customers so you can provide a fluid, personalised and seamless experience on every point of contact for increased",
    iconArray: ["ENGAGEMENT", "LOYALTY", "PROFITS", "TRUST"]
  }
}

export const secondText = {
  fr: {
    title: [
      "Lier l’efficacité ", "de votre enseigne… ", "et votre réseau ", "de vente."
    ],
    subtitle: "Avec Be-One, le magasin maîtrise l’intégralité de son réseau de vente grâce à une vue précise et unifiée de l’ensemble des clients.",
    text0: "Déployer et piloter votre stratégie marketing et commerciale ",
    text1: "sur une plateforme marketing et multicanale tout en donnant la possibilité à chaque acteur du réseau d’améliorer son business pour :",
    iconArray: ["DE RÉUSSITE", "DE PERFORMANCE", "D'OPPORTUNITÉS", "DE PUISSANCE"]
  },
  en: {
    title: [
      "Tying the effectiveness ", "of your brand ", "to your ", "sales network"
    ],
    subtitle: "Thanks to Be-One, the store manages its entire sales network with a precise, unified overview of all its customers.",
    text0: "This enables you to deploy and steer your marketing and business strategy ",
    text1: "and enable each network stakeholder to improve their business for more :",
    iconArray: ["SUCCESS", "PERFORMANCE", "OPPORTUNITIES", "POWER"]
  }
}

export const thirdText = {
  fr: {
    title: [
      "Unifier la communication ", "entre vous… ", "et vos franchisés."
    ],
    subtitle: "Leader sur le marché des franchisés depuis plus de 20 ans, Be-One réconcilie les enjeux locaux et nationaux sur une seule et même plateforme.",
    text0: "En temps réel et très simplement, elle vous permet d’",
    text1: "élaborer et de mesurer l’impact des opérations marketing de chaque magasin",
    text2: " pour des actions de communication :",
    iconArray: ["PERSONNALISÉES", "CONTEXTUALISÉES", "ENGAGEANTES", "IMPACTANTES"]
  },
  en: {
    title: [
      "Consolidated communication ", "with your ", "franchises"
    ],
    subtitle: " Be-One has been the market leader for franchises for over 20 years, reconciling local and national issues in a single platform.",
    text0: "It allows you ",
    text1: "to easily set up and measure the impact of marketing operations in each store in real-time,",
    text2: " for communication operations that are more :",
    iconArray: ["PERSONALISED", "CONTEXT-BASED", "ENGAGING", "IMPACTFUL"]
  }
}

export const fourthText = {
  fr: {
    title: [
      "Connecter ", "les données... ", "au marketing client."
    ],
    subtitle: "Be-One est la seule plateforme qui remet vos clients au centre de toutes vos actions.",
    text0: "Optimisez la fidélisation client grâce à une vision globale et précise à la fois,",
    text1: " vous créez des programmes relationnels sur-mesure",
    text2: " répondant parfaitement aux attentes de vos clients et à tous leurs besoins. Avec Be-One, vous vous reconnectez à tous vos clients pour qu’ils se sentent uniques.",
    cardArray: [
      [
        "UNE CONNAISSANCE CLIENT UNIFIÉE ET PRÉCISE",
        "Des données on et offline centralisées et partagées sur une seule et même plateforme pour une vision client unique sur l’intégralité du réseau."
      ],
      [
        "DES ACTIONS MARKETING ULTRA-CIBLÉES",
        "Des campagnes marketing adaptées aux spécificités locales pour générer plus de conversions, plus de ventes et plus de visites."
      ],
      [
        "PLUS D'ENGAGEMENT ET DE FIDÉLITÉ",
        "Des programmes de fidélisation personnalisés et engageants."
      ],
      [
        "UNE VÉRITABLE EXPÉRIENCE SANS COUTURE",
        "Une expérience unique, sur-mesure et contextualisée sur chaque point de contact."
      ],
    ]
  },
  en: {
    title: [
      "Linking ", "data ", "to customer marketing"
    ],
    subtitle: "Be-One is the only platform that places your customer at the centre of all of your operations.",
    text: "Using a vision that is both global and precise, you will create tailor-made relational programmes that perfectly meet all of their expectations and needs. Be-One helps you reconnect with all of your customers and make each one feel special.",
    cardArray: [
      [
        // le premier élément du tableau est le titre de la carte, le deuxième le soustitre
        " UNIFIED AND PRECISE CUSTOMER KNOWLEDGE ",
        "Centralise and share on and offline data on a single platform to get an overview of all customers in the network."
      ],
      [
        " SUPER-TARGETED MARKETING CAMPAIGNS",
        "National marketing campaigns with local adaptations to generate more conversions, more sales and more visits."
      ],
      [
        "MORE ENGAGEMENT AND LOYALTY",
        "Personalised, engaging loyalty programmes."
      ],
      [
        "A TRULY SEAMLESS EXPERIENCE",
        "A unique, tailored and contextualised experience at each point of contact."
      ],
    ]
  }
}

export const fifthText = {
  fr: {
    title: [
      "Rassembler ", "des experts... ", "autour de vos enjeux ", "business."
    ],
    subtitle: "Depuis toujours, nous intégrons et fusionnons toutes nos expertises autour du conseil, de la data, du digital, de la création et de l’IT, pour vous accompagner sur tous vos enjeux. ",
    text: "De l’intégration de la plateforme Be-ONE à son activation, en passant par la définition et le déploiement de vos stratégies marketing, nous sommes à vos côtés dans la réalisation de tous vos objectifs de performance.",
  },
  en: {
    title: [
      "Bringing experts ", "together ", "for your ", "business issues"
    ],
    subtitle: "We have, from the very beginning, always integrated and brought together expertise for consulting, data, digital technology, creation and IT, to be your partner in all of the issues you face on a daily basis.",
    text: "From the integration of the Be-One platform to its activation, including defining and deploying your marketing strategies, we are here for you as you meet all of your performance goals.",
  }
}

export const sixthText = {
  fr: {
    title: [
      "Allier ", "la technologie... ", "au retail."
    ],
    subtitle: "Pour vous offrir des dispositifs marketing encore plus puissants et auto-apprenants, la plateforme Be-One se connecte aussi avec la solution IBM Watson Campaign Automation.",
    h2: "Profitez de toute la puissance du marketing automation !",
    text: "Cette technologie d’automatisation des prises de décisions intelligentes permet :",
    iconArray: [
      "Des parcours fluides et individualisés",
      "Des animations et campagnes multicanales",
      "Des recommandations d’offres contextualisées",
      "Des reporting en temps réel",
      "Des analyses prédictives",
      "Des enquêtes de satisfaction automatisées",
      "Une ultra-personnalisation des contenus"
    ],
    partenaire: "Des partenaires solides et durables"
  },
  en: {
    title: [
      "Linking ", "technology ", "and retail"
    ],
    subtitle: "To provide you with even more powerful self-learning marketing tools, the Be-One platform also connects with the IBM Campaign Automation solution.",
    h2: "",
    text: "This smart decision-making automation technology enables you to:",
    iconArray: [
      "Create highly personalised content",
      "Generate automated satisfaction surveys",
      " Obtain predictive assessments",
      " Receive real-time reporting",
      " Provide contextual offer recommendations",
      " Launch multi-channel sales events and campaigns",
      " Provide fluid and individualised pathways "
    ],
    partenaire: "Build strong, long-standing partnerships"
  }
}


export const contact = {
  fr: {
    title: ["Un projet ? Envie d’en savoir plus sur Be-One et sur nos services ?", "Contactez-nous !"],
    form: {
      firstName: "Prénom *",
      lastName: "Nom *",
      company: "Société *",
      email: 'Email professionnelle *',
      phone: 'Téléphone *',
      message: '"Je souhaite en savoir plus sur BE-ONE...” (taper votre message)',
    },
    fieldsName: {
      firstName: "prénom ",
      lastName: "nom",
      company: "société",
      email: 'email',
      phone: 'téléphone',
      message: 'message',
    },
    incompleteField: "Merci de renseigner votre ",
    info: ["* Champs obligatoires", "Kiss The Bride collecte vos données car elles sont nécessaires pour le traitement de votre demande. Conformément à la loi n°78-17 du 6 janvier 1978 «Informatique et Libertés» modifiée, vous bénéficiez d’un droit d’accès et de rectification, ainsi qu’un droit d’opposition, que vous pouvez exercer en vous adressant à contact[@]kissthebride.fr"],
    modal: {
      success: [
        "Votre message a bien été envoyé",
        "Nous vous répondrons",
        "dans les plus brefs délais"
      ],
      error: [
        "Une erreur est survenue.",
        "Votre message n'a pu être envoyé."
      ]
    },
    send: "Envoyer ma demande",
    mobileUI: {
      contact: "Contactez-nous",
      back: "Retour"
    }
  },
    en: {
        // Dummy data en anglais pour tester la fonctionnalité, à modifier....
        title: ["Do you have a project? Do you want to learn more about Be-One and our services?", "Contact us"],
        form: {
            firstName: "Surname *",
            lastName: "Name *",
            company: 'Company *',
            email: "Professional e-mail adress *",
            phone: 'Phone *',
            message: '"I want to find out more about BE-ONE..." (type your message)'
        },
        fieldsName: {
            firstName: "first name ",
            lastName: "last name",
            company: "company",
            email: 'email',
            phone: 'phone',
            message: 'message',
        },
        incompleteField: "Please submit your ",
        info: ["* Mandatory fields ", "Kiss The Bride collects your data because we need it to process your request. In compliance with the French Data Protection Act no. 78-17 of 6 January 1978, as amended, you have a right to access, rectify and oppose your data being collected. You may exercise these rights by writing to contact[@]kissthebride.fr"],
        modal: {
            success: [
                "Message sent",
                "We will get back ",
                "to you shortly",
            ],
            error: [
                "Something went wrong...",
                "Please try again"
            ]
        },
        send: "Send",
        mobileUI: {
            contact: "Contact us",
            back: "Back"
        }
    }
}



export const features = {
  fr: {
    title: [
      "Unir les fonctionnalités ", "pertinentes... ", "au service", " du parcours client."
    ],

    text: "Ergonomique et simple d’utilisation, Be-One est une plateforme marketing évolutive, réunissant toutes les fonctionnalités utiles pour accroître la performance de vos magasins et améliorer la fidélisation en point de vente.",
    iconArray: [
      ["PILOTAGE CENTRALISÉ ET LOCAL", "des programmes relationnels clients."],
      ["CONNEXION AUX SI DE L’ENSEIGNE", "et consolidation de toutes les données clients (magasin, e-commerce, mobile, réseaux…)"],
      ["GESTION DES CAMPAGNES MARKETING OMNICANALES nationales et locales", "(courrier, e-mail, SMS, couponing)"],
      ["Configuration des droits d’accès et d’usage", "selon le profil utilisateur (centrale marketing, directeur de magasin, vue régionale…)"],
      ["Interface utilisateur plug & play multi-langues multi-navigateurs multi-devices", ""],
    ],
    subtitle: "Une véritable plateforme marketing innovante ",
    text2: "et performante centralisant tous les modules de gestion de la relation client pour établir un lien plus personnalisé et privilégié et définir les meilleures actions de fidélisation.",
    popInArray: [
      ["ANALYSE DATA ET CONNAISSANCE CLIENT",
        ["Connexion avec tous les systèmes de caisse",
          "Consolidation des données on et offline, locales et nationales",
          "Création d’un Référentiel Client Unique",
          "Gestion des comptes clients (historique des transactions, gestion des opt-in / opt-out, consultation et modification…)",
          "Intégration et enrichissement des données clients"]
      ],
      ["CIBLAGE ET EXTRACTION",
        ["Ciblage et requête multicritères",
          "Comptage et extraction sur l’ensemble de la base de données",
          "Segmentation sur-mesure selon les comportements de l’enseigne"]
      ],
      ["GESTION DES CAMPAGNES OMNICANALES",
        ["Déploiement sur tous les canaux : emailings, sms, rich sms, push notification, print",
          "Couponing de caisse dynamique (édition d’offres en temps réel)",
          "Intégration des éléments de communication de l’enseigne",
          "Outil d’automation marketing",
          "Moteur de recommandations personnalisées (calcul de points, mécaniques relationnelle et promotionnelle et préconisation d’opérations d’animation)",
          "Recommandation d’animations marketing"]
      ],
      ["REPORTING ET ANALYSE",
        ["Analyse et statistiques des opérations",
          "Suivi de l’activité clients",
          "Reporting opérationnel et géomarketing",
          "Tableaux de bord et data-visualisation",
        ]],
    ]
  },
  en: {
    title: [
      "Bringing useful features ", "to ", "customer pathway", "management"
    ],
    text: "BE-ONE is a simple, user-friendly, scalable platform that brings together all of the features that can help you increase your stores’ performance.",
    iconArray: [
      ["CENTRALISED AND LOCAL STEERING", "of customer relationship programmes ."],
      ["CONNECTION TO THE BRAND’S INFORMATION SYSTEM", "and consolidation of all customer data (store, e-commerce, mobile, networks, etc.)"],
      ["OMNI-CHANNEL MARKETING CAMPAIGN MANAGEMENT ON A NATIONAL AND LOCAL SCALE", "(mailings, e-mail, text messaging, coupons)"],
      ["Setting access and usage rights", "for each user profile (central marketing, store director, regional base, etc.)"],
      ["Multilingual, multi-browser, multi-device interface", ""],
    ],
    subtitle: "A truly innovative and powerful centralising marketing tool ",
    text2: "to share all of your data and increase your business performance.",
    popInArray: [
      ["DATA ANALYSIS AND CUSTOMER KNOWLEDGE ",
        ["Connection to all register systems",
          "Consolidation of on and offline, local and national data",
          "Creation of a unique customer reference",
          "Customer account management (transaction history, opt-in/opt-out management, reading and editing, etc.)",
          "Integration and supplementing customer data"]
      ],
      ["TARGETING AND EXTRACTION",
        ["Multi-criteria targeting and queries",
          "Data counting and extraction of the entire database",
          "Tailored segmentation depending on the brand’s behaviour"]
      ],
      ["OMNI-CHANNEL CAMPAIGN MANAGEMENT",
        ["Deployment on all channels: e-mail campaigns, text messages, rich text messaging, push notifications, print",
          "Dynamic register coupons (real-time offer creation)",
          "Integration with the brand’s communication",
          "Marketing automation tool",
          "Personalised recommendation generator (points calculator, relational and promotional mechanisms and suggestions for promotional events) ",
          "Suggestions for promotional events"]
      ],
      ["REPORTING AND ANALYSES",
        ["Analysis and statistics of each operation",
          "Customer activity monitoring",
          "Operational and geomarketing reports",
          "Dashboard and data visualisation",
        ]],
    ]
  }
}

export const whoText = {
    fr: {
        title: [
            "Marier la précision ",
            "des data... ",
            "à la force ",
            "des émotions."
        ],
        subtitle: "Voici l’ambition de Kiss The Bride, l’agence du marketing client.",
        text: "Nous accompagnons les marques et les entreprises sur toutes les étapes du parcours client, pour délivrer des expériences plus innovantes, plus engageantes et plus profitables.",
        button: "En savoir plus",
        ref: "Ils nous font confiance"
    },
    en: {
        title: [
            "The union ",
            "between data precision  ",
            "and the strength ",
            "of emotions."
        ],
        subtitle: "This is the ambition of Kiss The Bride, the customer marketing agency.",
        text: "We support brands and companies every step of the way to deliver ever-more innovative and engaging experiences along the customer journey.",
        button: "Learn more",
        ref: "They trust us"
    }
}

export const footerText = {
    fr: {
        title: [
            "La plateforme marketing omnicanal,",
            "par Kiss The Bride"
        ],
        boutonKiss: 'A propos de Kiss The Bride',
        adress: [
            ['Paris', '158 ter rue du Temple', '75003 Paris'],
            ['Lyon', '100 allée des Frênes', '69760 Limonest'],
            ['Lille', '395 rue du Général de Gaulle', "59700 Marcq en Baroeul"]
        ],
        callToAction: [
            "Inscrivez-vous à notre newsletter et faites",
            "le plein d'actus et de conseils pratiques autour",
            "du marketing client"
        ],
        insideCTA: ["Votre email", "JE LE VEUX !"],
        socialCTA: "Suivez-nous sur :",
        linkBottom: ["Be-One 2018", "Mentions légales", "https://www.kissthebride.fr/mentions-legales/", "Politique de confidentialité", "https://www.kissthebride.fr/politique-de-confidentialite/", "Gestion des cookies"],
        rgpd: "Votre adresse de messagerie est uniquement utilisée pour vous envoyer la newsletter Kiss The Bride. Vous pouvez à tout moment utiliser le lien de désabonnement intégré dans la newsletter. En savoir plus sur la politique de confidentialité.",
        rgpdLink: "https://www.kissthebride.fr/politique-de-confidentialite/",
        subscriptionSuccess: "Votre inscription a bien été prise en compte !",
        subscriptionError: "Une erreur est survenue. Veuillez réessayer."

    },
    en: {
        title: [
            "Be-One, the omni-channel marketing",
            "platform by Kiss The Bride"
        ],
        boutonKiss: 'About Kiss The Bride',
        adress: [
            ['Paris', '158 ter rue du Temple', '75003 Paris'],
            ['Lyon', '100 allée des Frênes', '69760 Limonest'],
          ['Lille', '395 rue du Général de Gaulle', "59700 Marcq en Baroeul"]
        ],
        callToAction: [
            "Sign up for our newsletter",
            "and stay up-to-date with tons of practical advice",
            "for customer marketing"
        ],
        insideCTA: ["Your e-mail", "YES, I DO !"],
        socialCTA: "Follow us on",
        linkBottom: ["Be-One 2018", "Legal Notice", "https://www.kissthebride.agency/legal-notice/", "Privacy policy", "https://www.kissthebride.agency/cookie-policy-of-loyalty-company-websites/", "Cookie settings"],
        rgpd: "Your email address is only used to send you the Kiss The Bride newsletter. You can always use the unsubscribe link included in the newsletter. Learn more about the data privacy policy.",
        rgpdLink: "https://www.kissthebride.agency/cookie-policy-of-loyalty-company-websites/",
        subscriptionSuccess: "Thank you for your registration.",
        subscriptionError: "An error has occurred. Please try again."

    }
}

