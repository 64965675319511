import React, {Component} from 'react';
import styled from 'styled-components';

import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";
import axios from 'axios';
import {contact} from '../../assets/text/text'
import {lightBlue} from '../../utils/colors';
import mailIconWhite from '../../assets/mailIconWhite.png';
import phoneIconWhite from '../../assets/phoneIconWhite.png';
import Spinner from '../UI/Spinner/Spinner'
import {GTM} from "../../utils/gtm";


class ContactData extends Component {

  state = {
    orderForm: {
      lastName: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: contact[this.props.language].form.firstName
        },
        value: "",
        validationRules: {
          required: true,
          minLength: 1,
          maxLength: 30
        },
        valid: false,
        touched: false
      },
      firstName: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: contact[this.props.language].form.lastName
        },
        value: "",
        validationRules: {
          required: true,
          minLength: 1,
          maxLength: 30
        },
        valid: false,
        touched: false
      },
      company: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: contact[this.props.language].form.company
        },
        value: "",
        validationRules: {
          required: true,
          minLength: 1,
          maxLength: 30
        },
        valid: false,
        touched: false
      },
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: contact[this.props.language].form.email
        },
        value: "",
        validationRules: {
          required: true,
          minLength: 7,
          maxLength: 30
        },
        valid: false,
        touched: false
      },
      phone: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: contact[this.props.language].form.phone
        },
        value: "",
        validationRules: {
          required: true,
          minLength: 7,
          maxLength: 30
        },
        valid: false,
        touched: false
      },
      message: {
        elementType: "textarea",
        elementConfig: {
          type: "text",
          placeholder: contact[this.props.language].form.message
        },
        value: "",
        validationRules: {
          required: false,
          minLength: 7,
          maxLength: 3000
        },
        valid: false,
        touched: false
      },
      sendToFax: { // HoneyPot
        elementType: "input",
        isHoneyPot: true,
        elementConfig: {
          type: "text",
          name: "sendToFax",
          id: "sendToFax",
          autoComplete: "off",
        },
        value: "",
        validationRules: {
          required: false,
          minLength: 0,
          maxLength: 9999
        },
        valid: true,
        touched: true
      },
    },
    formIsValid: false,
    isFormToggle: false,
    contactModal: {
      isToggled: false,
      success: false,
      error: null,
      waitingAnswer: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollToBottom, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollToBottom)
  }


  componentDidUpdate(prevProps) {

    if (prevProps.language !== this.props.language) {

      const updatedState = {...this.state};
      for (let input in updatedState.orderForm) {
        let updatedInput = {...updatedState.orderForm[input]}
        updatedInput.elementConfig.placeholder = contact[this.props.language].form[input]
      }
      this.setState({
        state: updatedState
      })

    }
  }

  confirmOrderHandler = (event) => { // Useful to prevent reloading on submit, for e.g.
    event.preventDefault();

    if (this.state.formIsValid) {
      this.setState({
        ...this.state,
        contactModal: {
          ...this.state.contactModal,
          isToggled: true
        }
      })
      const formData = {};
      for (let formElementIdentifier in this.state.orderForm) {
        formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
      }

      formData['lang'] = this.props.language;

      const origin = window.location.origin;
      const url = origin + '/contact';

      axios.post(url, formData)
        .then(resp => {
          console.log(resp)
          if (resp.data.success) {
            const lang = [this.props.language];
            window.dataLayer.push({'event': 'mailSended', lang});
            this.setState({
              ...this.state,
              contactModal: {
                ...this.state.contactModal,
                success: true,
                waitingAnswer: false
              }
            })

            GTM.add({'event': 'contact', 'email': formData.email, 'phone_number': formData.phone});

          } else {
            this.setState({
              ...this.state,
              contactModal: {
                ...this.state.contactModal,
                error: true,
                waitingAnswer: false
              }
            })
          }
        })
        .catch(err => {
          this.setState({
            ...this.state,
            contactModal: {
              ...this.state.contactModal,
              error: true,
              waitingAnswer: false
            }
          })
          console.log(err)
        })
    } else {
      let updatedOrderForm = {...this.state.orderForm};
      for (let formElement in updatedOrderForm) {
        let updatedInput = {...updatedOrderForm[formElement]};
        if (!updatedInput.touched) {
          updatedInput.elementConfig.placeholder = contact[this.props.language].incompleteField + contact[this.props.language].fieldsName[formElement]
          updatedInput.touched = true;
          updatedOrderForm[formElement] = updatedInput;
        }

      }
      this.setState({orderForm: updatedOrderForm})
    }


  }

  checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    return isValid;
  }

  inputChangeHandler = (event, inputIdentifier) => {
    // cloning the contactForm state to avoid mutating the original state
    const updatedOrderForm = {
      ...this.state.orderForm
    };
    // cloning deeper to access the inputIdentifier (=elementType, elementConfig, value) without mutating the original state
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validationRules);
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
    }

    this.setState({
      orderForm: updatedOrderForm,
      formIsValid: formIsValid
    });
  }

  onModalHandler = () => {

    this.setState({
      ...this.state,
      contactModal: {
        ...this.state.contactModal,
        isToggled: false,
        success: false,
        error: null,
        waitingAnswer: false,

      }
    })
  }

  onToggleContactForm = (bool) => {
    this.setState(prevState => {
      return {
        isFormToggle: !prevState.isFormToggle
      }
    }, this.scrollToTop)
    this.props.toggleContactForm(bool)
  }

  scrollToTop = () => {
    if (!this.state.isFormToggle) return;
    const form = document.getElementById('form');
    form.scrollIntoView()
  }

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    let formTorender = null;

    let contactModal = null;
    if (this.state.contactModal.isToggled) {
      let answer = null;

      if (this.state.contactModal.waitingAnswer) {
        answer = (
          <Spinner/>
        )
      } else if (this.state.contactModal.success) {
        answer = (
          <Title>
            <b>{contact[this.props.language].modal.success[0]}<br/></b>
            {contact[this.props.language].modal.success[1]}<br/>
            {contact[this.props.language].modal.success[2]}
          </Title>
        )
      } else if (this.state.contactModal.error) {
        answer = (
          <Title>
            <b>{contact[this.props.language].modal.error[0]}<br/></b>
            {contact[this.props.language].modal.error[1]}
          </Title>)
      }

      contactModal = (
        <ContactModal onClick={this.onModalHandler}>
          {answer}
        </ContactModal>
      )
    }


    if (this.props.bottomReached) {

      formTorender = (<FormLayout id='form'>
        <TextBlock>
          <Title>{contact[this.props.language].title[0]}<br/><b>{contact[this.props.language].title[1]}</b></Title>
        </TextBlock>
        <ContactDetail>
          <ButtonBlue>
            <img src={phoneIconWhite} height="20" alt='phoneIcon' style={{marginRight: '10px'}}/>
            <div>{contact[this.props.language].phone}</div>

          </ButtonBlue>
          <ButtonBlue>
            <img src={mailIconWhite} height="18" alt='mailIcon' style={{marginRight: '10px'}}/>
            <div>EMAIL</div>

          </ButtonBlue>
        </ContactDetail>
        {contactModal}
        <Fields onSubmit={this.confirmOrderHandler}>
          {formElementsArray.map(el => {
            return (
              <Input
                key={el.id}
                isHoneyPot={el.config.isHoneyPot}
                elementType={el.config.elementType}
                elementConfig={el.config.elementConfig}
                value={el.config.value}
                change={(event) => this.inputChangeHandler(event, el.id)}
                invalid={!el.config.valid}
                shouldValidate={el.config.validationRules}
                touched={el.config.touched}/>
            );
          })}

          <Button btnType="Success" disabled={!this.state.formIsValid}><b>Envoyer ma demande</b></Button>
        </Fields>
        <TextBlock>
          <Paragraph><b>{contact[this.props.language].info[0]}<br/>{contact[this.props.language].info[1]}
          </b></Paragraph>
        </TextBlock>
      </FormLayout>)

    } else if (!this.state.isFormToggle) {
      formTorender = (
        <ButtonDown onClick={() => this.onToggleContactForm(true)}>
          {contact[this.props.language].mobileUI.contact}
        </ButtonDown>)

    } else {
      formTorender = (
        <FormLayout id='form'>
          <TextBlock>
            <Title>{contact[this.props.language].title[0]}<br/><b>{contact[this.props.language].title[1]}</b></Title>
          </TextBlock>
          <ContactDetail>
            <ButtonBlue href="tel:+33155390910">
              <img src={phoneIconWhite} height="20" alt='phoneIcon' style={{marginRight: '10px'}}/>
              <div>{contact[this.props.language].phone}</div>

            </ButtonBlue>
            <ButtonBlue href={contact[this.props.language].emailAddress}>
              <img src={mailIconWhite} height="18" alt='mailIcon' style={{marginRight: '10px'}}/>
              <div>EMAIL</div>

            </ButtonBlue>

          </ContactDetail>
          {contactModal}

          <Fields onSubmit={this.confirmOrderHandler}>

            {formElementsArray.map(el => {
              return (
                <Input
                  key={el.id}
                  isHoneyPot={el.config.isHoneyPot}
                  elementType={el.config.elementType}
                  elementConfig={el.config.elementConfig}
                  value={el.config.value}
                  change={(event) => this.inputChangeHandler(event, el.id)}
                  invalid={!el.config.valid}
                  shouldValidate={el.config.validationRules}
                  touched={el.config.touched}/>
              );
            })}

            <Button
              btnType="Success"
              onClick={this.confirmFormHandler}><b>{contact[this.props.language].send}</b></Button>
          </Fields>

          <ButtonDown onClick={() => this.onToggleContactForm(false)}>
            {contact[this.props.language].mobileUI.back}
          </ButtonDown>
          <TextBlock>
            <Paragraph><b>{contact[this.props.language].info[0]}<br/>{contact[this.props.language].info[1]}
            </b></Paragraph>
          </TextBlock>
        </FormLayout>
      );
    }

    return (
      <div>
        {formTorender}
      </div>
    );
  }
}

export default ContactData;

const Fields = styled.form`
    display: flex;
    flex-direction: column;
    width: 90%;
`

const FormLayout = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow-y:visible;
    top: 0;
    width: 100vw;
    height: auto;
    background-color: white;
    z-index: 30;
    align-items: center;
`

const Title = styled.div`
    width: 80%;
    font-family: "Quicksand";
    font-size: 1.7rem;
`

const TextBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Quicksand";
    flex-direction: column;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
`
const ButtonDown = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Quicksand";
    flex-direction: column;
    text-align: center;
    background-color:  ${lightBlue};
    height: 7vh;
    width: 100%;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    position: fixed;
    bottom: 0;
    cursor: pointer;
`

const ButtonBlue = styled.a`
display: flex;
color: white;
background: ${lightBlue};
border: 2px solid ${lightBlue};
height: 20px;
width: auto;
border-radius: 27px;
font-family: "Quicksand";
font-size: 0.9em;
font-weight: bold;
padding: 12px 20px;
margin-bottom: 1.5rem;
text-decoration: none;
`

const ContactDetail = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 90%;
`
const Paragraph = styled.div`
font-size: 0.7em;
width: 90%;
font-weight: 700;
color: #cccccc;
padding-bottom: 4em;
`

const ContactModal = styled.div`
    width: 90%;
    height: 54vh;
    display: flex;
    background-color: rgba(255,255,255,0.6);
    z-index: 30;
    margin-top: 15.5em;
    position: absolute;
    justify-content: center;
    text-align: center;
    align-items: center;
`
