import React from 'react';
import styled from "styled-components";

class CookieModal extends React.PureComponent {
  state = {};

  render() {
    return (
      <CookieModalComponent>
        <CookieModalForm>
          <div>
            <CookieModalTitle>
              <span>Préférences en matière de dépôt de données</span>
              <CookieModalClose className="JS-cookie-modal-close" src="" alt="Fermer"/>
            </CookieModalTitle>
            <CookieModalIntroduction>
              <p>Lorsque vous visitez des sites Web, ils peuvent stocker ou récupérer des données dans votre navigateur.
                Ce stockage est souvent nécessaire pour la fonctionnalité de base du site Web.</p>
              <p>L’analyse peut être utilisée comme stockage. La confidentialité est importante pour nous, vous avez
                donc la possibilité de désactiver ce type de stockage qui n’est pas être nécessaire pour le
                fonctionnement de base du site Web. Le blocage de l’analyse peut avoir un impact sur votre expérience
                sur le site Web.</p>
            </CookieModalIntroduction>
            <div className="CookieModal-parameters"></div>
          </div>
          <CookieModalSubmit>
            <button type="submit">Sauvegarder</button>
            <br/><br/>
          </CookieModalSubmit>
        </CookieModalForm>
      </CookieModalComponent>
    );
  }
}

export default CookieModal;

const CookieModalComponent = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  max-width: 430px;
  background-color: #fff;
  padding: 20px 20px 0 20px;
  overflow-y: auto;
  z-index: 2000;
  transform: translate(100%);
  transition: transform 0.5s ease-in-out;
`

const CookieModalForm = styled.form`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
`

const CookieModalTitle = styled.div`
    display: flex;
    margin-bottom: 25px;
    span {
      line-height: 1.2;
      font-weight: bold;
      font-size: 24px;
    }
`

const CookieModalClose = styled.img`
    width: 30px;
    height: 30px;
    margin-left: 20px;
    cursor: pointer;
    svg {
      width: 30px;
      height: 30px;
    }
`

const CookieModalIntroduction = styled.div`
    margin-bottom: 30px;
    p {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
`

const CookieModalSubmit = styled.div`
    button {
      width: 100%;
      line-height: 40px;
      border: 1px solid #000;
      margin-bottom: 20px;
    }
`
