import React from 'react';
import YouTube from 'react-youtube';

class VideoView extends React.Component {

    _onReady = (event) => {
        event.target.playVideo();
    }

    render() {
        const style = {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%'
        };
        const opts = {
            width: '100%',
            height: '100%',
            playerVars: {
                playsinline: 0,
                rel: 0,
                showinfo:0,
                modestbranding: 0
            }
        };

        return (
            <div style={{...style}}>
                <YouTube
                    ref={this.ytRef}
                    videoId="l5WnvggdkBA"
                    opts={opts}
                    onReady={this._onReady}
                    onEnd={this.props.handleclose}
                />
            </div>

        );
    }
}

export default VideoView