import React from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor'

//assets
import v5Mright from '../../assets/v5M/v5Mright.png';
import v5Mleft from '../../assets/v5M/v5Mleft.png';
import { fifthText } from '../../assets/text/text';
import { lightBlue } from '../../utils/colors';

import { TextBlock } from '../../component/style/style'

class FifthM extends React.PureComponent {
    state = {
        isVisible: {
            right: false,
            left: false
        }
    }

    onVisibilityHandler = (isVisible, side) => {
        if (!this.state.isVisible[side] && isVisible) {
            this.setState({
                ...this.state,
                isVisible: {
                    ...this.state.isVisible,
                    [side]: true
                }
            })
        }
    }

    render() {

        return (
            <div>
                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'left')}>
                    <LeftPict
                        offSetStart={-30}
                        left={this.state.isVisible.left}
                        src={v5Mleft}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>


                <TextBlock>

                    <Title>
                        {fifthText[this.props.language].title[0]}<b>{fifthText[this.props.language].title[1]}<br /></b>
                        {fifthText[this.props.language].title[2]}<b>{fifthText[this.props.language].title[3]}<br /></b>
                    </Title>
                </TextBlock>

                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'right')}>
                    <RightPict
                        offSetStart={30}
                        right={this.state.isVisible.right}
                        src={v5Mright}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>

                <TextBlock>
                    <Subtitle>{fifthText[this.props.language].subtitle}</Subtitle>
                    <Paragraph>
                        {fifthText[this.props.language].text}
                    </Paragraph>
                </TextBlock>
            </div >
        )
    }
}


export default FifthM

const Img = styled.img`
    width: 93%;
    height: auto;
    opacity: 0;
    transform: translateX(${props => props.offSetStart}vh);
`

const LeftPict = styled(Img)`
    margin: 3rem 0 0 4rem;
    ${props => props.left && css`
    animation-duration: 1s;
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
        @keyframes fadeInLeft {
        from {
          opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateX(-10vh)
        }
      }
    `}    
`

const RightPict = styled(Img)`
margin: 2rem 0 0 -2rem;
${props => props.right && css`
animation-duration: 1s;
animation-name: fadeInRight;
animation-fill-mode: forwards;
    @keyframes fadeInRight {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(10vh)
    }
  }
`}    
`

const Title = styled.div`
width: 90%;
font-family: "Quicksand";
font-size: 1.5rem;
`
const Subtitle = styled.div`
font-size: 1em;
color: ${ lightBlue};
font-weight: 700;
width: 90%;
`
const Paragraph = styled.div`
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`
