import React from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor'

//assets
import v1MRight from '../../assets/v1M/v1MRight.png';
import v1MLeft from '../../assets/v1M/v1MLeft.png';
import plusIcon from '../../assets/plusIcon.png';
import { firstText } from '../../assets/text/text';
import { lightBlue } from '../../utils/colors';

import { TextBlock } from '../../component/style/style'

class FirstM extends React.PureComponent {
    state = {
        isVisible: {
            right: false,
            left: false
        }
    }

    onVisibilityHandler = (isVisible, side) => {
        if (!this.state.isVisible[side] && isVisible) {
            this.setState({
                ...this.state,
                isVisible: {
                    ...this.state.isVisible,
                    [side]: true
                }
            })
        }
    }

    render() {

        return (
            <div>
                <div id="WhyBeOne" />
                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'right')}>
                    <RightPict
                        offSetStart={30}
                        right={this.state.isVisible.right}
                        src={v1MRight}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>
                <TextBlock>
                    <Title>
                        {firstText[this.props.language].title[0]}
                        <b>{firstText[this.props.language].title[1]}<br />
                            {firstText[this.props.language].title[2]}</b>
                    </Title>
                </TextBlock>
                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'left')}>
                    <LeftPict
                        offSetStart={-30}
                        left={this.state.isVisible.left}
                        src={v1MLeft}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>

                <TextBlock>
                    <Subtitle>{firstText[this.props.language].subtitle}</Subtitle>
                    <Paragraph>
                        {firstText[this.props.language].text}
                    </Paragraph>
                </TextBlock>
                <IconBlock>
                    {firstText[this.props.language].iconArray.map((sub, i) => {
                        return (
                            <div key={i} style={{ width: '50%', paddingTop: '2rem' }}>
                                <img src={plusIcon} width="60" alt={'iconPLus'} />
                                <div style={{ fontSize: '0.8em', paddingTop: '1em' }}><b>{sub}</b></div>
                            </div>
                        )
                    })}
                </IconBlock>
            </div >
        )
    }
}


export default FirstM

const Img = styled.img`
    opacity: 0;
    transform: translateX(${props => props.offSetStart}vh);
`

const LeftPict = styled(Img)`
    width: 76%;
    height: auto;
    margin: 2rem 0 0 4rem;
    ${props => props.left && css`
    animation-duration: 1s;
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
        @keyframes fadeInLeft {
        from {
          opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateX(-10vh)
        }
      }
    `}    
`

const RightPict = styled(Img)`
    width: 80%;
    height: auto;
    margin: 3rem 0 0 1rem;
${props => props.right && css`
animation-duration: 1s;
animation-name: fadeInRight;
animation-fill-mode: forwards;
    @keyframes fadeInRight {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(10vh)
    }
  }
`}    
`

const IconBlock = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
padding-top: 2em;
align-items: center;
text-align: center;
width: 100%;
align-self: center;
`
const Title = styled.div`
width: 90%;
font-family: "Quicksand";
font-size: 1.8rem;
`
const Subtitle = styled.div`
font-size: 1em;
color: ${ lightBlue};
font-weight: 700;
width: 90%;
`
const Paragraph = styled.div`
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`
