import styled from 'styled-components'

import { lightBlue, lightBlack } from '../../utils/colors'

export const Title = styled.div`
width: 80%;
font-family: "Quicksand";
font-size: 1.9rem;
`

export const TextBlock = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-family: "Quicksand";
flex-direction: column;
text-align: center;
padding-top: 2rem;
width: 100%;
`

export const BlueButton = styled.a`
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 90%;
    color: white;
    padding: 1rem 2rem;
    box-sizing: border-box;
    border-radius: 2rem;
    font-size: 1rem;
    text-align: center;
`

export const Subtitle = styled.div`
    font-size: 15px;
    color: ${lightBlue};
    font-weight: 700;
`
export const Paragraph = styled.div`
    font-size: 15px;
    line-height: 22px;
    font-weight: 700;
    color: ${lightBlack};
`

export const Content = styled.div`
display: flex;
flex-direction: column;
height: auto;
color: ${lightBlack};
align-items: center;
width:644px;
`

export const PhotoContent = styled.div`
margin: 0 auto;
position: relative;
height: auto;
display: flex;
justify-content: space-evenly;
padding-top: 15em;
    padding-bottom:15em;
`