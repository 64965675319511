import React from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor'
import Slider from 'react-slick';

//assets
import v4Mright from '../../assets/v4M/v4Mright.png';

import FlyingCard from '../../assets/v4M/FlyingCard.png';
import FlyingCard2 from '../../assets/v4M/FlyingCard2.png';
import FlyingCard3 from '../../assets/v4M/FlyingCard3.png';
import FlyingCard4 from '../../assets/v4M/FlyingCard4.png';
import { fourthText } from '../../assets/text/text';
import { lightBlue } from '../../utils/colors';

import { TextBlock } from '../../component/style/style';

class FourthM extends React.PureComponent {
    state = {
        isVisible: {
            right: false
        }
    }

    onVisibilityHandler = (isVisible, side) => {
        if (!this.state.isVisible[side] && isVisible) {
            this.setState({
                ...this.state,
                isVisible: {
                    ...this.state.isVisible,
                    [side]: true
                }
            })
        }
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };

        const iconArray = [FlyingCard, FlyingCard2, FlyingCard3, FlyingCard4]

        return (
          <div>
              <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'right')}>
                  <RightPict
                    offSetStart={30}
                    right={this.state.isVisible.right}
                    src={v4Mright}
                    height='600'
                    alt="rightPict" />
              </VisibilitySensor>
              <TextBlock>

          <Title>
            {fourthText[this.props.language].title[0]}
            <b>{fourthText[this.props.language].title[1]}<br />
              {fourthText[this.props.language].title[2]}</b>
          </Title>
        </TextBlock>
        <TextBlock>
          <Subtitle>{fourthText[this.props.language].subtitle}</Subtitle>
          <Paragraph>
            {fourthText[this.props.language].text0}
            <SecondTitle>{fourthText[this.props.language].text1}</SecondTitle>
            {fourthText[this.props.language].text2}
          </Paragraph>
        </TextBlock>

              <Slider className='cardSlider' {...settings}>
                  {fourthText[this.props.language].cardArray.map((card, i) => {

                      return (
                        <div key={i}>
                            <CardDiv>
                                <img className="pictoCarroussel" src={iconArray[i]} width="70" alt={'iconPLus'} style={{}} />
                                <div style={{ width: '80%', fontSize: '1.2em' }}><b>{card[0]}</b></div>
                                <div style={{ width: '80%', fontSize: '1em', paddingTop: '1em' }}><b>{card[1]}</b></div>
                            </CardDiv>
                        </div>

                      )
                  })}
              </Slider>
          </div >
        )
    }
}


export default FourthM


const Img = styled.img`
        width: 93%;
    height: auto;
    margin: 4rem 0 0 -2rem;
    opacity: 0;
    transform: translateX(${props => props.offSetStart}vh);
`


const RightPict = styled(Img)`
${props => props.right && css`
animation-duration: 1s;
animation-name: fadeInRight;
animation-fill-mode: forwards;
    @keyframes fadeInRight {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(10vh)
    }
  }
`}    
`

const CardDiv = styled.div`
display: flex !important;
flex-wrap: wrap;
flex-direction: column; 
align-items: center;
justify-content: flex-start;
text-align: center;
/* width: 80% !important; */
align-self: center;
background: linear-gradient(to bottom right,#472a7d,#5fc6f0);
color: white;
font-weight: bold;
height: 285px;
margin: 0 15px;
box-shadow: 0px 5px 17px 0px #808080b3;
`
const Title = styled.div`
width: 90%;
font-family: "Quicksand";
font-size: 1.5rem;
`
const Subtitle = styled.div`
font-size: 1em;
color: ${ lightBlue};
font-weight: 700;
width: 90%;
`
const Paragraph = styled.div`
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`
const SecondTitle = styled.h2`
display: inline;
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`
