export const GTM = {
  add: (event) => {
    if (!GTM.dataLayerActive()) {
      return;
    }
    window.dataLayer.push(event);
    console.log("GTM.add", event);
  },

  dataLayerActive: () => {
    return Array.isArray(window.dataLayer);
  },
}
