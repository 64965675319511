import React from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor'

//assets
import WhoLogo1 from '../../assets/who/WhoLogo1.png';
import WhoLogo2 from '../../assets/who/WhoLogo2.png';
import WhoLogo3 from '../../assets/who/WhoLogo3.png';
import WhoLogo4 from '../../assets/who/WhoLogo4.png';
import WhoLogo5 from '../../assets/who/WhoLogo5.png';
import WhoLogo6 from '../../assets/who/WhoLogo6.png';


import WhoLeft from '../../assets/who/WhoLeft.png';
import WhoRight from '../../assets/who/WhoRight.png';

import { whoText } from '../../assets/text/text';
import { lightBlue } from '../../utils/colors';

import { TextBlock } from '../../component/style/style'

class WhoM extends React.PureComponent {
    state = {
        isVisible: {
            right: false,
            left: false
        }
    }

    onVisibilityHandler = (isVisible, side) => {
        if (!this.state.isVisible[side] && isVisible) {
            this.setState({
                ...this.state,
                isVisible: {
                    ...this.state.isVisible,
                    [side]: true
                }
            })
        }
    }

    render() {

        const refArray = [
            WhoLogo1,
            WhoLogo2,
            WhoLogo3,
            WhoLogo4,
            WhoLogo5,
            WhoLogo6
        ];

        return (
            <div>
                <div id="WhoWeAre" />
                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'left')}>
                    <LeftPict
                        offSetStart={-30}
                        left={this.state.isVisible.left}
                        src={WhoLeft}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>


                <TextBlock>

                    <Title>
                        {whoText[this.props.language].title[0]}<br /><b>{whoText[this.props.language].title[1]}</b>
                        {whoText[this.props.language].title[2]}<b> <br />
                            {whoText[this.props.language].title[3]}</b>
                    </Title>
                </TextBlock>

                <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'right')}>
                    <RightPict
                        offSetStart={30}
                        right={this.state.isVisible.right}
                        src={WhoRight}
                        height='600'
                        alt="gauchePict" />
                </VisibilitySensor>

                <TextBlock>
                    <Subtitle>{whoText[this.props.language].subtitle}</Subtitle>
                    <Paragraph>
                        {whoText[this.props.language].text}
                    </Paragraph>
                    <Button href="https://www.kissthebride.fr/" target="_blank">
                        {whoText[this.props.language].button}
                    </Button>
                    <Ref>{whoText[this.props.language].ref}</Ref>
                </TextBlock>
                <IconBox>
                    {refArray.map((icon, i) => {
                        return <img key={i} src={icon} width="150" height="150" alt={'iconPLus'} />
                    })}
                </IconBox>
                <div style={{ height: '10vh' }} />
            </div >
        )
    }
}


export default WhoM

const Img = styled.img`
    width: 93%;
    height: auto;
    opacity: 0;
    transform: translateX(${props => props.offSetStart}vh);
`

const LeftPict = styled(Img)`
    margin: 0rem 0 0 4rem;
    width: 73%;
    ${props => props.left && css`
    animation-duration: 1s;
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
        @keyframes fadeInLeft {
        from {
          opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateX(-10vh)
        }
      }
    `}    
`

const RightPict = styled(Img)`
margin: 2rem 0 0 -2rem;
${props => props.right && css`
animation-duration: 1s;
animation-name: fadeInRight;
animation-fill-mode: forwards;
    @keyframes fadeInRight {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(10vh)
    }
  }
`}    
`

const Title = styled.div`
width: 90%;
font-family: "Quicksand";
font-size: 1.5rem;
`
const Subtitle = styled.div`
font-size: 1em;
color: ${ lightBlue};
font-weight: 700;
width: 90%;
`

const Button = styled.a`
color: ${ lightBlue};
border: 2px solid ${ lightBlue};
height: 20px;
width: auto;
border-radius: 27px;
font-family: "Quicksand";
font-size: 1em;
font-weight: bold;
padding: 12px 30px;
margin: 2em 0;
text-decoration: none;
`

const Paragraph = styled.div`
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`
const IconBox = styled.div`
padding-top: 2em;
display: flex;
flex-wrap: wrap;
justify-content:center;

`

const Ref = styled(Subtitle)`
font-size: 1.3em;
`