import React from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor'
import Slider from 'react-slick';

//assets
import IBM from '../../assets/v6M/IBM.png';
import v6M from '../../assets/v6M/v6M.png';
import v6Micon1 from '../../assets/v6M/v6MIcon1.png';
import v6Micon2 from '../../assets/v6M/v6MIcon2.png';
import v6Micon3 from '../../assets/v6M/v6MIcon3.png';
import v6Micon4 from '../../assets/v6M/v6MIcon4.png';
import v6Micon5 from '../../assets/v6M/v6MIcon5.png';
import v6Micon6 from '../../assets/v6M/v6MIcon6.png';
import v6Micon7 from '../../assets/v6M/v6MIcon7.png';

import cloudera from '../../assets/partenaires/cloudera.png'
import IBMWatson from '../../assets/partenaires/IBMWatson.png'
import oracle from '../../assets/partenaires/oracle.png'
import sas from '../../assets/partenaires/sas.png'
import talend from '../../assets/partenaires/talend.png'
import xerox from '../../assets/partenaires/xerox.png'
import XMPIE from '../../assets/partenaires/XMPIE.png'

import { sixthText } from '../../assets/text/text';
import { lightBlue } from '../../utils/colors';

import { TextBlock } from '../../component/style/style';

class SixthM extends React.PureComponent {
  state = {
    isVisible: {
      right: false
    }
  }

  onVisibilityHandler = (isVisible, side) => {
    if (!this.state.isVisible[side] && isVisible) {
      this.setState({
        ...this.state,
        isVisible: {
          ...this.state.isVisible,
          [side]: true
        }
      })
    }
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      centerMode: true,
    };

    const iconArray = [v6Micon1, v6Micon2, v6Micon3, v6Micon4, v6Micon5, v6Micon6, v6Micon7];
    const parteArray = [cloudera, IBMWatson, oracle, sas, talend, xerox, XMPIE]

    return (
      <div>
        <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'right')}>
          <RightPict
            offSetStart={30}
            right={this.state.isVisible.right}
            src={IBM}
            height='600'
            alt="rightPict" />
        </VisibilitySensor>
        <TextBlock>

          <Title>
            {sixthText[this.props.language].title[0]}
            <b>{sixthText[this.props.language].title[1]}<br />
              {sixthText[this.props.language].title[2]}</b>
          </Title>
        </TextBlock>
        <VisibilitySensor partialVisibility onChange={(isVisible) => this.onVisibilityHandler(isVisible, 'left')}>
          <LeftPict
            offSetStart={-30}
            left={this.state.isVisible.left}
            src={v6M}
            height='600'
            alt="gauchePict" />
        </VisibilitySensor>

                <TextBlock>
                    <Subtitle>{sixthText[this.props.language].subtitle}</Subtitle>
                    <Paragraph>
                      {sixthText[this.props.language].h2 ? (<SecondTitle>{sixthText[this.props.language].h2} </SecondTitle>):""}
                        {sixthText[this.props.language].text}
                    </Paragraph>
                </TextBlock>

        <Slider className='iconSlider2' {...settings}>
          {sixthText[this.props.language].iconArray.map((sub, i) => {
            return (
              <div key={i}>
                <Pictodiv>
                  <img src={iconArray[i]} width="80" alt={'iconPLus'} style={{}} />
                  <div style={{ paddingTop: '2em', fontSize: '1em', textTransform: 'uppercase' }}><b>{sub}</b></div>
                </Pictodiv>
              </div>

            )
          })}
        </Slider>
        <TextBlock>
          <Partenaire>{sixthText[this.props.language].partenaire}</Partenaire>
        </TextBlock>
        <IconBox>
          {parteArray.map((icon, i) => {
            return <img key={i} src={icon} width="150" height="150" alt={'iconPLus'} />
          })}
        </IconBox>
      </div>
    )
  }
}


export default SixthM


const Img = styled.img`
    height: auto;
    opacity: 0;
    transform: translateX(${props => props.offSetStart}vh);
`


const RightPict = styled(Img)`
width: 90%;
margin: 2rem 0 0em -3.5rem;
${props => props.right && css`
animation-duration: 1s;
animation-name: fadeInRight;
animation-fill-mode: forwards;
    @keyframes fadeInRight {
    from {
      opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(10vh)
    }
  }
`}    
`

const LeftPict = styled(Img)`
    width: 100%;
    margin: 2rem 0 0em 4.5rem;
    ${props => props.left && css`
    animation-duration: 1s;
    animation-name: fadeInLeft;
    animation-fill-mode: forwards;
        @keyframes fadeInLeft {
        from {
          opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateX(-10vh)
        }
      }
    `}    
`

const Pictodiv = styled.div`
display: flex !important;
text-align: center;
flex-direction: column;
align-items: center;
flex-wrap: wrap;
flex-direction: column; 
align-self: center;
font-weight: bold;
height: 185px;
margin: 0 15px;
width: auto !important;
`


const Title = styled.div`
width: 90%;
font-family: "Quicksand";
font-size: 1.5rem;
`
const Subtitle = styled.div`
font-size: 1em;
color: ${ lightBlue};
font-weight: 700;
width: 90%;
`
const Partenaire = styled(Subtitle)`
font-size: 1.3em;
`
const Paragraph = styled.div`
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
padding-bottom: 2rem;
`
const IconBox = styled.div`
padding-top: 2em;
display: flex;
flex-wrap: wrap;
justify-content:center;

`
const SecondTitle = styled.h2`
display: inline;
font-size: 1em;
width: 90%;
line-height: 22px;
font-weight: 700;
`
