import React from 'react';
import './InputStyle.css'

const input = (props) => {

    let inputElement = null;
    let containerClasses = ["Input"];

    const inputClasses = ["InputElement"];
    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push("Invalid")
    }
    if (props.touched) {
        inputClasses.push("Touched")
    }
    if(props.isHoneyPot) {
        containerClasses.push("SendToFax")
    }


    switch (props.elementType) {
        case ("input"):
            inputElement = <input
                onChange={props.change}
                className={inputClasses.join(" ")}
                {...props.elementConfig}
                value={props.value} />;
            break;
        case ("textarea"):
            inputElement = <textarea
                onChange={props.change}
                style={{ height: '100px' }}
                className={inputClasses.join(" ")}
                {...props.elementConfig}
                value={props.value} />;
            break;
        default:
            inputElement = <input
                onChange={props.change}
                className={inputClasses.join(" ")}
                {...props.elementConfig}
                value={props.value} />;
    }

    return (
        <div className={containerClasses.join(" ")} style={{ width: props.maxWidth }} >
            <label className="Label">{props.label}</label>
            {inputElement}
        </div>
    );
}

export default input;
