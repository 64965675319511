import React from 'react';
import styled from "styled-components";

class CookieBanner extends React.PureComponent {
  state = {};

  render() {
    return (
      <CookieBannerComponent>
        <CookieBannerContainer>
          <CookieBannerWrapper>
            <CookieBannerDescription>Ce site web stocke des données telles que les cookies pour activer
              les fonctionnalités nécessaires du site, y compris l'analyse. Vous pouvez modifier ces paramètres à tout
              moment ou accepter les paramètres par défaut.
            </CookieBannerDescription>
            <CookieBannerLinks>
              <CookieBannerLink>
                <a href="https://www.kissthebride.fr/politique-de-confidentialite/">Politique de confidentialité et d'utilisation des cookies</a>
              </CookieBannerLink>
            </CookieBannerLinks>
            <CookieBannerFooter>
              <CookieBannerParameterButton>
                <button className="JS-cookie-modal-open" type="button">Paramètres des cookies</button>
              </CookieBannerParameterButton>
              <CookieBannerMainButtons>
                <button type="button" className="JS-cookie-modal-accept">J'accepte</button>
                <button type="button" className="JS-cookie-modal-refuse">Je refuse</button>
              </CookieBannerMainButtons>
            </CookieBannerFooter>
          </CookieBannerWrapper>
        </CookieBannerContainer>
      </CookieBannerComponent>
    );
  }
}

export default CookieBanner;

const CookieBannerComponent = styled.div`
  position: fixed;
  bottom: 30px;
  transform: translateY(calc(100% + 30px));
  width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 1100;
`

const CookieBannerContainer = styled.div`
    width: calc(100% - 40px);
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    @media (min-width: 1400px) {
      max-width: 1320px;
    }
`

const CookieBannerWrapper = styled.div`
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 48px 0 rgba(0,0,0,0.12);
`

const CookieBannerDescription = styled.div`
    letter-spacing: -0.8px;
    line-height: 1.4;
`

const CookieBannerLinks = styled.ul`
    display: flex;
    list-style: none;
    margin-bottom: 25px;
    margin-top: 10px;
    flex-direction: column;
    padding: 0;
    @media (min-width: 768px) {
      margin-top: 2px;
      flex-direction: row;
    }
`

const CookieBannerLink = styled.li`
    &::before {
        content: "•";
        margin-left: 6px;
        margin-right: 2px;
    }
    &:first-child {
        &::before {
          @media (min-width: 768px) {
            content: "";
            margin: 0;
          }
        }
    }
    & > * {
        color: #000;
        cursor: pointer;
        text-decoration: underline;
    }
`

const CookieBannerFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
`

const CookieBannerParameterButton = styled.div`
    font-size: 0;
    width: 100%;
    @media (min-width: 768px) {
      width: auto;
    }
    button {
      width: 100%;
      line-height: 40px;
      padding: 0 20px;
      border: 1px solid #F7F7F7;
      background-color: #F7F7F7;
      transition: background-color 0.5s, border-color 0.5s;
      margin-bottom: 15px;
      cursor: pointer;
      @media (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
      }
      &:hover, &:focus {
        background-color: #888888;
        border-color: #888888;
      }

    }
`

const CookieBannerMainButtons = styled.div`
    font-size: 0;
    width: 100%;
    @media (min-width: 768px) {
      width: auto;
    }
    button {
      width: 100%;
      line-height: 40px;
      padding: 0 20px;
      border: 1px solid #000;
      background-color: #fff;
      transition: background-color 0.5s, color 0.5s;
      margin-bottom: 15px;
      cursor: pointer;
      @media (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
      &:hover, &:focus {
        background-color: #000;
        color: #fff;
      }
    }
`