import * as $ from 'jquery';
//import { TweenMax } from 'gsap';

$.fn.extend({
    box: function () {
        let thisElement = this.get(0);
        let box = thisElement.getBoundingClientRect();
        let $this = $(thisElement);
        box.offsetLeft = $this.offset().left;
        box.offsetTop = $this.offset().top;

        return box;
    }
});

export default class TextGalaxy {

    static registredFunctions;
    static resetScrollSpeed = false;

    static init(reset = false) {

        if (reset) {
            TextGalaxy.stopWatchTextImage();
            TextGalaxy.resetScrollSpeed = true;
            setTimeout(function () {
                TextGalaxy.resetScrollSpeed = false;
            }, 10)
        }

        TextGalaxy.registredFunctions = new Map();

        setTimeout(function () {
            // Permet d'attendre l'affichage des éléments générés en même temps
            TextGalaxy.watchTextImage('image1', 'text1');
            TextGalaxy.watchTextImage('image2', 'text2');
            TextGalaxy.watchTextImage('image3', 'text3');
            TextGalaxy.watchTextImage('image4', 'text4');
            TextGalaxy.watchTextImage('image5', 'text5');
            TextGalaxy.watchTextImage('image6', 'text6');
            TextGalaxy.watchTextImage('image7', 'text7');
            TextGalaxy.watchTextImage('image8', 'text8');
            TextGalaxy.watchTextImage('image9', 'text9');
            TextGalaxy.watchTextImage('image11', 'text11');
            TextGalaxy.watchTextImage('image12', 'text12');
            TextGalaxy.watchTextImage('image14', 'text12');
            TextGalaxy.watchTextImage('image13', 'text13');
            TextGalaxy.watchTextImage('image15', 'text13');
            TextGalaxy.watchTextImage('image16', 'text16');
            TextGalaxy.watchTextImage('image17', 'text17');

            setTimeout(function () {
                // Permet d'avoir un rafraichissement plus rapide que du scroll
                var raf = window.requestAnimationFrame ||
                    window.webkitRequestAnimationFrame ||
                    window.mozRequestAnimationFrame ||
                    window.msRequestAnimationFrame ||
                    window.oRequestAnimationFrame;
                var $window = $(window);
                var lastScrollTop = $window.scrollTop();
                if (raf) {
                    loop();
                }
                function loop() {
                    var scrollTop = $window.scrollTop();

                    if (TextGalaxy.resetScrollSpeed) {
                        return false;
                    }

                    if (lastScrollTop === scrollTop) {
                        raf(loop);
                        return false;
                    } else {
                        lastScrollTop = scrollTop;

                        // fire scroll function if scrolls vertically
                        TextGalaxy.updateScreenView();

                        raf(loop);
                    }
                }
            }, 20);

            TextGalaxy.updateScreenViewOnResize();

        }, 0);


    }

    static stopWatchTextImage() {
        // Suppression des éléments dans le DOM
        let tracker = document.querySelectorAll('.tracker');
        tracker.forEach(node => {
            node.remove('body')
        });
        let trackerTextBack = document.querySelectorAll('.trackerTextBack')
        trackerTextBack.forEach(node => {
            node.remove('body')
        });

        window.removeEventListener('resize', TextGalaxy.updateScreenViewOnResize);
    }

    static updateScreenView() {
        TextGalaxy.registredFunctions.forEach(function (updateTracker, idImage, map) {
            updateTracker();
        });
    }

    static updateScreenViewOnResize() {
        window.addEventListener('resize', function () {
            TextGalaxy.updateScreenView();
        });
    }


    static watchTextImage(idImage, idText) {
        let image = document.getElementById(idImage);
        let text = document.getElementById(idText);

        // On créer les fake éléments
        let $tracker = $('<div class="tracker"></div>');
        let $trackerText = $('<div class="trackerText">' + text.innerHTML + '</div>');
        $tracker.addClass('eHhQNZ'); // TODO Class pour formater le texte

        // On les ajoute au body
        $tracker.appendTo('body');
        $trackerText.appendTo($tracker);

        let tracker = $tracker.get(0);
        let trackerText = $trackerText.get(0);
        let parentImage = $(image).parents('.Plx').get(0);
        let parentText = $(text).parents('.Plx').get(0);

        // On reconfigure le zIndex des éléments
        text.style.zIndex = 8;
        text.style.position = 'relative';
        trackerText.style.position = 'absolute';

        image.style.zIndex = 10;
        image.style.position = 'relative';
        tracker.style.zIndex = 11;
        tracker.style.position = 'absolute';
        tracker.style.overflow = 'hidden';
        tracker.style.color = 'white';

        // configuration du tracker d'arrière plan
        let $trackerTextBack = $('<div class="trackerTextBack">' + text.innerHTML + '</div>');

        $trackerTextBack.appendTo('body');
        $trackerTextBack.addClass('eHhQNZ');

        let trackerTextBack = $trackerTextBack.get(0);

        // On reconfigure le zIndex des éléments
        trackerTextBack.style.position = 'absolute';
        trackerTextBack.style.zIndex = -1;
        trackerTextBack.style.color = 'green';
        trackerTextBack.style.opacity = parentText.style.opacity;

        TextGalaxy.registredFunctions.set(idImage, function (id) {
            if (typeof id === 'undefined') {
                TextGalaxy.updateTracker(trackerText, tracker, trackerTextBack, text, image, parentText, parentImage);
            }
        });
        TextGalaxy.registredFunctions.get(idImage)();
    }


    static comparePositions(p1, p2) {
        var r1, r2;
        r1 = p1[0] < p2[0] ? p1 : p2;
        r2 = p1[0] < p2[0] ? p2 : p1;
        return r1[1] > r2[0] || r1[0] === r2[0];
    }

    static updateTracker(trackerText, tracker, trackerTextBack, text, image, parentText, parentImage) {

        //console.time("RefreshView of " + image.id);

        let imageBound = image.getBoundingClientRect();
        let documentBound = document.body.getBoundingClientRect();
        let textBound = text.getBoundingClientRect();
        let textPositionTop = textBound.top - documentBound.top;
        let textPositionLeft = textBound.left - documentBound.left;
        let imagePositionTop = imageBound.top - documentBound.top;
        let imagePositionLeft = imageBound.left - documentBound.left;

        let collision = TextGalaxy.comparePositions([textPositionLeft, textPositionLeft + textBound.width], [imagePositionLeft, imagePositionLeft + imageBound.width]) &&
            TextGalaxy.comparePositions([textPositionTop, textPositionTop + textBound.height], [imagePositionTop, imagePositionTop + imageBound.height]);

        // On check si l'image est dans la vue
        if (collision) {

            tracker.style.visibility = 'visible';
            tracker.style.top = Math.ceil(imageBound.top - documentBound.top) + 'px';
            tracker.style.left = Math.ceil(imageBound.left - documentBound.left) + 'px';
            tracker.style.width = Math.ceil(imageBound.width) + 'px';
            tracker.style.height = Math.ceil(imageBound.height) + 'px';

            trackerText.style.top = Math.ceil(textPositionTop - imagePositionTop) + 'px';
            trackerText.style.left = Math.ceil(textPositionLeft - imagePositionLeft) + 'px';
            trackerText.style.width = Math.ceil(textBound.width) + 'px';
            trackerText.style.height = Math.ceil(textBound.height) + 'px';

            // element arrière plan
            trackerTextBack.style.color = '#3c3c3b';
            trackerTextBack.style.top = Math.ceil(textPositionTop) + 'px';
            trackerTextBack.style.left = Math.ceil(textPositionLeft) + 'px';
            trackerTextBack.style.width = Math.ceil(textBound.width) + 'px';
            trackerTextBack.style.height = Math.ceil(textBound.height) + 'px';

            trackerTextBack.style.visibility = 'visible';
            text.style.visibility = 'hidden';

            if (typeof parentImage !== 'undefined') {
                trackerText.style.opacity = parentImage.style.opacity;
            }
            if (typeof parentText !== 'undefined') {
                trackerTextBack.style.opacity = parentText.style.opacity;
            }
        }
        else {
            tracker.style.visibility = 'hidden';
            text.style.visibility = 'visible';
            trackerTextBack.style.visibility = 'hidden';
        }
        //console.timeEnd("RefreshView of " + image.id);
    }


}

