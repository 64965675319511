import React from 'react';
import styled from "styled-components";

class DefaultOverlay extends React.PureComponent {
  state = {};

  render() {
    return (
     <DefaultOverlayComponent className="DefaultOverlay"></DefaultOverlayComponent>
    );
  }
}

export default DefaultOverlay;

const DefaultOverlayComponent = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.48);
  z-index: 1100;
`